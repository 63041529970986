import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Lottie from "lottie-react";
// Replace these imports with the actual paths to your images
import redirectLogoAnimation from "../../Assets/redirectloadingAnimation.json";
import redirectbottomlogo from "../../Assets/Svg/redirectBottonLogo.svg";
import { getDataFromLocalStorage, removeDataFromLocalStorage, setDataOnLocalStorage } from "../../Utils/globalUtilities";
import {
  UserData,
  LOCAL_STORAGE_DATA_KEYS,
} from "../../Utils/Constants/localStorageDataModels";
import { DASHBOARD } from "../../Utils/Constants/RouteConstant";

const RedirectPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSessionId = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get("code");
      const carrier = getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.CARRIER);

      if (code) {
        try {
          const apiUrl = `${process.env.REACT_APP_API_URL}bucket=auth&operationName=gitCallback&carrier=${carrier}&code=${code}`;
          const response = await axios.get(apiUrl);

          const sessionId = response.data.data.sessionId;

          if (sessionId) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${sessionId}`;
            setDataOnLocalStorage(
              LOCAL_STORAGE_DATA_KEYS.SESSION_ID,
              sessionId
            );
            try {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}bucket=account&operationName=profile`,
                {
                  headers: {
                    Authorization: `${sessionId}`,
                  },
                }
              );
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              const result: { message: string; data: UserData } =
                await response.json();
              if (result.message === "ok") {
                setDataOnLocalStorage(
                  LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG,
                  result.data.orgs[0].org_name
                );
                setDataOnLocalStorage(
                  LOCAL_STORAGE_DATA_KEYS.USER_DATA,
                  result.data
                );
                removeDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.CARRIER);
                navigate(DASHBOARD);
              }
            } catch (error) {
              console.error("Failed to fetch user data:", error);
            }
          } else {
            console.error("Session ID not found in response");
          }
        } catch (error) {
          console.error("Failed to fetch session ID:", error);
        }
      } else {
        console.error("Code parameter is missing");
      }
    };

    fetchSessionId();
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-between h-screen bg-[#080814]">
      {/* Redirect Logo */}
      <div className="flex flex-col items-center justify-center flex-grow">
        <Lottie
          animationData={redirectLogoAnimation}
          loop={true}
          className="w-32 h-32"
        />
        <h1 className="mt-4 text-[18px] text-[#FFFFFF] font-bold">
          Please wait...
        </h1>
        <p className="text-[16px] text-[#8B8B99] font-normal mt-1">
          It may take a moment.
        </p>
      </div>

      {/* Bottom Logo */}
      <div className="">
        <img
          src={redirectbottomlogo}
          alt="Redirect Bottom Logo"
          className="w-20 h-20"
        />
      </div>
    </div>
  );
};

export default RedirectPage;
