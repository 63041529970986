import React, { useEffect, useState } from "react";
import axios from "axios";
import RepositoryTable from "./RepositoryTable";
import WelcomeScreen from "../../WelcomeScreen/WelcomeScreen";
import Spinner from "../../../../Common/Spinner";
import { useNavigate } from "react-router-dom";
import { getDataFromLocalStorage } from "../../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../../Utils/Constants/localStorageDataModels";
import { CODE_PULSE_CONFIGURE } from "../../../../../Utils/Constants/RouteConstant";
import AnimationLoader from "../../../../Common/AnimationLoader";
import EmptyRepo from "./EmptyRepo";

interface RepositoriesProps {
  className?: string;
}

interface Repository {
  name: string;
  branch_name: string;
  update_time: string;
  issue_count: string;
  status: string;
  repoCount: string;
  repo_id: string;
  project_id: number;
  uuid: string;
}

interface ApiResponse {
  message: string;
  data: Repository[];
}

const Repositories: React.FC<RepositoriesProps> = ({ className }) => {
  const navigate = useNavigate();
  const [repositories, setRepositories] = useState<Repository[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";

  useEffect(() => {
    const fetchRepositories = async () => {
      try {
        const apiURL = `${
          process.env.REACT_APP_API_URL
        }bucket=pulse&operationName=listMappedRepositories&orgName=${encodeURIComponent(
          selectedOrg
        )}`;
        const response = await axios.get<ApiResponse>(apiURL, {
          headers: {
            Authorization: `${token}`,
          },
        });

        if (response.data.message === "ok") {
          // if (response.data.data.length == 0) {
          //   navigate(CODE_PULSE_CONFIGURE);
          // }
          setRepositories(response.data.data);
        } else {
          setError("Failed to fetch repositories.");
        }
      } catch (err) {
        setError("An error occurred while fetching repositories.");
      } finally {
        setLoading(false);
      }
    };

    fetchRepositories();
  }, [selectedOrg]);

  if (error) {
    return (
      <div
        className={`flex flex-col bg-[#080814] h-screen ${className} px-5 py-4`}
      >
        <div className="text-red-500 ml-5">{error}</div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col bg-[#080814] h-screen ${className} px-5 py-4`}
    >
      {loading ? (
        <AnimationLoader />
      ) : (
        repositories.length === 0 ? 
          <EmptyRepo />
        :
          <RepositoryTable tableData={repositories} />  
      )}
    </div>
  );
};

export default Repositories;
