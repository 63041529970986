import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import MainLogo from "../../../../Assets/Svg/Logo.svg";
import submitButton from "../../../../Assets/Svg/submitButton.svg";
import {
  LOCAL_STORAGE_DATA_KEYS,
  UserData,
} from "../../../../Utils/Constants/localStorageDataModels";
import { getDataFromLocalStorage } from "../../../../Utils/globalUtilities";
import AutoResizeTextarea from "./AutoResizeTextarea";

interface ChatPromptProps {
  setSelectedThreadId: (newId: string) => void;
  setInputMessage: (prompt: string) => void;
  setIsCreatingNew: (newState: boolean) => void;
  onUpdateThreadList: (newThread: { threadid: string; title: string }) => void;
  handleSubmit: (e?: React.FormEvent, message?: string) => Promise<void>;
}

const ChatPrompt: React.FC<ChatPromptProps> = ({
  setInputMessage,
  setIsCreatingNew,
  handleSubmit,
}) => {
  const [selectedPrompt, setSelectedPrompt] = useState<string>("");
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const storedUserData = getDataFromLocalStorage<UserData>(
      LOCAL_STORAGE_DATA_KEYS.USER_DATA
    );
    if (storedUserData && typeof storedUserData === "object") {
      setUserData(storedUserData as UserData);
    }
  }, []);
  const handlePrompt = async (prompt: string) => {
    setInputMessage(prompt);
    setSelectedPrompt(prompt);
    setIsCreatingNew(true);
    await handleSubmit(undefined, prompt);
  };

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (selectedPrompt.trim()) {
      setInputMessage(selectedPrompt);
      setIsCreatingNew(true);
      await handleSubmit(e, selectedPrompt);
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line on Shift+Enter
        return;
      } else {
        // Submit on Enter without Shift
        e.preventDefault();
        handleFormSubmit(e as unknown as FormEvent<HTMLFormElement>);
      }
    }
  };
  const prompts = [
    "What is CI/CD, and how can I implement it in my project?",
    "Explain the microservices architecture and its benefits.",
    "How do I optimize in a production environment?",
    // "What are the key differences between monolithic and distributed systems?",
    "How do I manage containers using Docker?",
    // "What are the best practices for writing clean and maintainable code?",
  ];

  return (
    <div className="w-full bg-[#0D0D22] p-4 text-white flex flex-col justify-between items-center h-full">
      <div className="flex flex-col my-auto justify-center items-center w-full">
        <div>{/* <img src={MainLogo} alt="logo" /> */}</div>
        <div className="text-center">
          <h1 className="text-[40px]">👋</h1>
          <h1 className="text-[#B2B2C1] font-bold text-[28px]">
            Hi, {userData?.name ? userData.name : userData?.username}
          </h1>
          <h1 className="text-[#B2B2C1] font-bold text-[28px]">
            What would you like to know
          </h1>
        </div>
        <div className=" w-full flex justify-center">
          <div className="grid grid-cols-2 gap-4 w-[80%]  p-4 rounded-lg justify-center items-center">
            {prompts.map((prompt, index) => (
              <button
                key={index}
                className={`p-2 w-full rounded-lg shadow-md text-center border border-[#1B1B41] ${
                  selectedPrompt === prompt
                    ? "bg-[#131330] text-white"
                    : "bg-[#1B1B41] text-[#B2B2C1]"
                }`}
                onClick={() => handlePrompt(prompt)}
              >
                {prompt}
              </button>
            ))}
          </div>
        </div>
      </div>

      <form
        onSubmit={handleFormSubmit}
        className="w-full p-4 z-10 flex justify-between items-end pb-5"
      >
        <AutoResizeTextarea
          value={selectedPrompt}
          onKeyDown={handleKeyDown}
          onChange={(e) => setSelectedPrompt(e.target.value)}
          placeholder="Type your message..."
          isInitialLoading={false}
          handleSubmit={handleFormSubmit}
        />
      </form>
    </div>
  );
};

export default ChatPrompt;
