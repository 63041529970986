import React from 'react'
import Header from '../../Components/Common/Headers'
import Footer from '../../Components/Common/Footer'

const ContactUsPage = () => {
    return (
        <div className="bg-[#080814] min-h-screen text-white  w-full max-w-[2050px] mx-auto">
            <Header />
            <div className="text-center px-5 md:text-nowrap md:px-[14%] my-5 ">
                <h1 className="text-4xl md:text-5xl font-bold mb-2 text-[#D1D1E3]">Contact Us</h1>
                <p className="mb-8 text-sm px-10 md:px-0 md:text-lg text-[#9494A1]">Get in touch with us easily!</p>
            </div>
            <div className="flex sm:flex-row flex-col space-y-4 sm:space-y-0 justify-center mt-10 mb-12 sm:mb-32 ">
                <div className="flex flex-col w-[85vw] sm:w-[16vw] mx-10">
                    <p className='text-[27px] font-medium text-[#D1D1E3]'>Address</p>
                    <p className='text-[17px] text-[#9494A1] font-thin mt-2'>P-25 South Extension Part 2 New Delhi - 110049</p>
                </div>
                <div className="flex flex-col w-[80vw] sm:w-[12vw] mx-10">
                    <p className='text-[27px] font-medium text-[#D1D1E3]'>Phone</p>
                    <p className='text-[17px] text-[#9494A1] font-thin mt-2'>+91 99994 10384</p>
                </div>
                <div className="flex flex-col w-[12vw] mx-10">
                    <p className='text-[27px] font-medium text-[#D1D1E3]'>Email</p>
                    <a href="mailto:launch@univerze.com" className='text-[17px] text-[#9494A1] font-bold mt-2'>hello@drcode.ai</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUsPage