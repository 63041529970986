import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

type FAQItem = {
  title: string;
  paragraph: string;
};

const faqData: FAQItem[] = [
  {
    title: "Do I need to buy license for every user in my development team?",
    paragraph:
      "You may choose to start with a few team members but it is advisable to get entire team on board to get all PRs reviewed.",
  },
  {
    title: "Is there any limit on the number of repositories or number of APIs?",
    paragraph:
      "No, there is no limit on the number of repositories and APIs.",
  },
  {
    title:
      "We have a large team. Do you offer bulk pricing?",
    paragraph:
      "Yes, we do offer custom pricing.",
  },
  {
    title:
      "Do I need to add credit card to start?",
    paragraph:
      "No, you can start without adding any payment method. To continue after trial period, you can go in the billing section to update your payment details.",
  },
  {
    title:
      "As a developer, how do I try DrCode if I am not an admin?",
    paragraph:
      "You can try our feature, Code Test, for automated unit testing. It does not require any admin permissions and can be used at an individual level as well.",
  },
];

const FAQ: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="mt-16 mb-10">
      <div className="mx-4 sm:mx-16 md:mx-32 lg:mx-64">
        <h1 className="text-center font-bold text-[#D1D1E3] text-[24px] sm:text-[30px] mb-8">
          Frequently Asked Questions
        </h1>
        {faqData.map((faq, index) => (
          <div
            key={index}
            className="border-2 border-[#1B1B41] rounded-lg overflow-hidden mb-4"
          >
            <div
              onClick={() => handleToggle(index)}
              className="flex justify-between items-center cursor-pointer text-[#D1D1E3] p-3 sm:p-4"
            >
              <div className="flex items-center space-x-2">
                {/* <FaQuestionCircle className="text-red-500" /> */}
                <span className="text-[16px] font-[500] sm:text-base">{faq.title}</span>
              </div>
              {openIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            {openIndex === index && (
              <p className="text-[#9494A1] font-[300] m-0 p-3 sm:p-4 text-xs sm:text-base">
                {faq.paragraph}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
