import React, { useState } from "react";
import tick from "../../Assets/Svg/priceTick.svg";
import ContactUsModal from "../Common/ContactUsModal";
import { useNavigate } from "react-router-dom";

interface Feature {
  title?: string;
  icon?: string;
  points: string[];
  subtitle?: string;
  more?: string;
}

interface PriceCardProps {
  title: string;
  tags: string[];
  price: string;
  pricePerMonth: string;
  features: Feature[];
  buttonText: string;
  extraFeature?: string;
  cardText?: string;
}

const PriceCard: React.FC<PriceCardProps> = ({
  title,
  tags,
  price,
  pricePerMonth,
  features,
  buttonText,
  extraFeature,
  cardText
}) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div
      className={`  rounded-xl  p-10 max-w-sm shadow-lg bg-[rgba(19,19,48,1)] flex flex-col justify-between 
        ${title === "Organization"
          ? "border border-[#2E2E60]"
          : "border border-[#A380FF]"
        }
        `}
    >
      <div>
        <h2 className="text-2xl font-bold mb-2 text-[#D1D1E3]">{title}</h2>
        <div className="flex flex-wrap gap-2 mb-4">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="bg-[#1B1B41] text-[#B2B2C1] text-xs font-semibold px-2.5 py-0.5 rounded"
            >
              {tag}
            </span>
          ))}
        </div>
        <div className="text-xl font-bold">{price}</div>
        <div className="text-gray-500 mb-4">{pricePerMonth}</div>
        <hr className="border-t border-[#2E2E60] mb-4" />

        <div>
          {features.map((feature, index) => (
            <div key={index} className="mb-4">
              <div className="flex items-center gap-2 mb-2">
                {feature.icon && (
                  <img
                    src={feature.icon}
                    alt={feature.title}
                    className="w-5 h-5"
                  />
                )}
                {feature.title && (
                  <h3 className="font-semibold text-[#D1D1E3]">
                    {feature.title}
                  </h3>
                )}
              </div>
              {feature.subtitle && (
                <p className="text-[#B2B2C1] mb-2 font-semibold">{feature.subtitle}</p>
              )}
              <ul className="space-y-2 text-[#B2B2C1]">
                {feature.points.map((point, pointIndex) => (
                  <li key={pointIndex} className="flex items-center">
                    <img src={tick} alt="icon" className="mr-2 w-4 h-4" />
                    <span>{point}</span>
                  </li>
                ))}
              </ul>
              {feature.more && (
                <p className="text-[#B2B2C1] mt-2">{feature.more}</p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="w-[100%]flex flex-col justify-end">
        <button
          onClick={() => buttonText === "Start free 15-Day trial" ? handleNavigate("/login") : setIsModalOpen(true)}
          className={`w-full text-[#F3EFFE] py-2 px-4 rounded-lg mt-4 ${buttonText === "Start free 15-Day trial"
            ? "bg-[#875BF8]"
            : "border border-[#F3EFFE] bg-transparent"
            }`}
        >
          {buttonText}
        </button>
        {cardText && (
          <div className="text-[#B2B2C1] font-[300] mt-2 px-12 text-[13px]">{cardText}</div>
        )}
      </div>
      {isModalOpen &&
        <ContactUsModal isOpen={isModalOpen} closeModal={closeModal} />
      }
    </div>

  );
};

export default PriceCard;
