import React, { useState } from "react";
import bgBanner from "../../Assets/Svg/BannerMaskGroup.svg";
import { getDataFromLocalStorage } from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";
import ContactUsModal from "../Common/ContactUsModal";

const API_URL = process.env.REACT_APP_API_URL;

const Banner: React.FC = () => {
  const [email, setEmail] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div
      className="bg-cover bg-[#4A3288] bg-center p-4 sm:p-6 md:p-8 flex flex-col lg:flex-row justify-between items-center rounded-xl shadow-lg mx-4 sm:mx-8 md:mx-16 lg:mx-32 gap-5 mb-20"
      style={{ backgroundImage: `url(${bgBanner})` }}
    >
      {/* Text Section */}
      <div className="flex-1 px-2 sm:px-4 lg:px-5 mb-4 lg:mb-0 text-center lg:text-left">
        <h1 className="text-2xl sm:text-3xl md:text-3xl lg:text-4xl font-bold text-[#D1D1E3] leading-tight">
          We're not just selling a tool, we're selling time!
        </h1>
        <p className="mt-2 text-sm sm:text-base lg:text-lg text-[#8B8B99] max-w-xl mx-auto lg:mx-0">
          Why always prioritise between time and quality when with DrCode you
          can get both.
        </p>
      </div>

      {/* Input Section */}
      <div className="relative w-full lg:w-auto max-w-md">
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          placeholder="Enter your work email"
          className="w-full pr-4 sm:pr-20 md:pr-48 lg:pr-48 border border-[#392668] bg-[#131330] rounded-lg pl-2 py-3 sm:py-4 focus:outline-none focus:border-[#392668] text-[#62627C] text-sm sm:text-base"
        />
        <button
          className="absolute right-0 top-0 bg-[#875BF8] rounded-lg text-white px-2 sm:px-3 md:px-4 py-2 sm:py-3 m-1 transition duration-200 whitespace-nowrap text-sm sm:text-base"
          onClick={() => {setIsModalOpen(true)}}
        >
          Request a Call
        </button>
      </div>
      {isModalOpen &&
        <ContactUsModal isOpen={isModalOpen} closeModal={closeModal} emailInput={email}/>
      }
    </div>
  );
};

export default Banner;
