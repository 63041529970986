import React from "react";

import { points } from "./points";
import Content from "../TNC/Content";
import Header from "../Common/Headers";
import { RxDotFilled } from "react-icons/rx";

interface Point {
  title: string;
  subtext?: string;
  para?: string;
  point1?: string;
  point2?: string;
}

const Details: React.FC = () => {
  const splitIndex = Math.ceil(points.length * 0.4);
  const firstSection = points.slice(0, splitIndex);
  const secondSection = points.slice(splitIndex);

  console.log(firstSection)

  return (
    <div className="w-full mb-10">
      <div
        className="w-full  "
        style={{
          background:
            "linear-gradient(180deg, rgba(77, 104, 255, 0.15) 0%, rgba(10, 10, 31, 0) 100%)",
        }}
      >
        <Header />
        <div className="text-center px-[14%] mt-5">
          <h1 className="text-4xl font-bold mb-2 text-[#D1D1E3]">
            Privacy Policy
          </h1>
          <p className="mb-8 text-[16px] text-[#9494A1]">
            Effective Date: July 2024
          </p>
        </div>
        <div className=" px-[14%] mt-10 mb-0">
          {/* {firstSection.map((data: Point, index: number) => (
            <Content
              key={index}
              title={data.title}
              subtext={data.subtext}
              para={data.para}
              point1={data.point1}
              point2={data.point2}
            />
          ))} */}
          <div className="h-[275px] w-[100%]">
            <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">1. Introduction</h2>
            <p className="mb-2 text-[#B2B2C1] text-justify">
              The purpose of this Privacy Policy is to inform users of Dr Code about how we collect, use, disclose, and safeguard their personal information when they interact with our website. It also aims to ensure transparency regarding the types of data we collect and the measures we take to protect it. By using our website, users agree to the terms outlined in this Privacy Policy, which is intended to build trust and help them understand their rights concerning their data.
            </p>
            <p className="mb-2 text-[#B2B2C1] text-justify">
              At Dr. Code, we are committed to respecting and protecting the privacy of our users. We recognize the importance of maintaining the confidentiality and security of personal data and strictly adhere to applicable data protection laws, including the Information Technology Act, 2000, and the Rules enacted thereunder. We employ best practices and industry standards to safeguard user information and are dedicated to ensuring that users have control over their data. Our commitment to data privacy is integral to our operations, and we continuously strive to provide a secure online experience for our users.
            </p>
          </div>
          <div className="h-[820px] w-[100%]">
            <h2 className="font-bold text-xl mb-2 text-[#B2B2C1] ">2. Information We Collect</h2>
            <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">We may collect various types of personal information from our users to provide and improve our services. This information may include, but is not limited to:
            </h3>
            <ul className="list-none pl-0 text-justify">
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Contact Details:</span> Such as your name, email address, phone number, and postal address.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Usage Data: </span> Information on how you interact with our website and services, including the pages visited, time spent, clicks, and other engagement metrics.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Account Information: </span> Details you provide when creating an account, such as your username and password.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Financial Information: </span> Information related to any transactions carried out through our website.
                </span>
              </li>
            </ul>
            <h3 className="text-lg font-normal mb-2 text-[#B2B2C1]">We collect personal information through multiple channels:
            </h3>
            <ul className="list-none pl-0 text-justify">
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Direct User Input:</span>  Information you provide when you fill out forms, register an account, or communicate with us directly.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Automated Collection: </span> Data that is automatically collected when you access or use our website, such as IP addresses, browser type, device information, and browsing behavior through cookies and similar tracking technologies.
                </span>
              </li>
            </ul>
            <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">We collect personal information directly from you when you interact with our services or communicate with us. This may include:</h3>
            <ul className="list-none pl-0 text-justify">
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Account Login Information:</span>  Such as your username and email, etc., which you share with us when registering or accessing your account.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Survey Responses: </span> Data collected through surveys or questionnaires that you participate in as part of our research activities or other company-related engagements.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Financial Transaction Details: </span> Information related to any financial transactions conducted through our services, including payment information and transaction history.
                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Customer Support Information: </span> Details you provide when reaching out to our customer support team, including any feedback, inquiries, or complaints, as well as your contact details.
                </span>
              </li>
            </ul>
            <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">We may receive your personal information indirectly through authorized third-party sources, including:
            </h3>
            <ul className="list-none pl-0 text-justify">
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Third-Party Account Partners:</span>  Data shared with us from services you connect to our platform, such as social media accounts, which may include your profile information and interactions authorized by you.

                </span>
              </li>
              <li className="flex items-start mb-2 text-[#B2B2C1]">
                <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
                <span> <span className="font-[800]">Other Third-Party Services: </span> Personal information obtained through other services with which you interact or which you authorize to share information with us, including data aggregators, analytics providers, and other partners.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="w-full px-[14%]">
        <div className="w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">3. How We Use Your Information</h2>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">We use the information we collect from you for a variety of purposes to provide, improve, and personalize our services. These purposes include:
          </h3>

          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Service Delivery:</span> To deliver and manage the services you have requested, including account management, processing transactions, providing customer support, and responding to your inquiries.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Personalization:</span> To enhance your experience on our website by tailoring the content and features to match your preferences and interests. This may include displaying personalized recommendations, targeted content, or specific features based on your usage patterns.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Analytics and Improvement:</span> To analyze usage patterns, monitor and assess the performance of our services, and identify areas for improvement. This helps us understand how users interact with our platform and make data-driven decisions to enhance our services.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Communication:</span> To send you relevant information regarding updates, changes, and offers related to our services. This includes sending you newsletters, promotional messages, and notifications. You can opt out of marketing communications at any time.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Security and Compliance:</span> To protect our website and users from fraudulent activities, unauthorized access, or other security risks. We also use your information to comply with legal obligations, resolve disputes, enforce our policies, and prevent harm to our users or others.
              </span>
            </li>
          </ul>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Our data processing activities are based on either your explicit consent or our legitimate interests:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Consent:</span> For specific data processing activities, such as marketing communications or sharing information with third-party partners, we will obtain your explicit consent. You have the right to withdraw your consent at any time by contacting us or using the opt-out options provided in our communications.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Legitimate Interests:</span> In certain cases, we process your information based on our legitimate interests, which may include improving our services, enhancing user experience, ensuring website security, and conducting business operations. When processing data under legitimate interests, we ensure that our actions do not override your rights and freedoms by conducting a thorough assessment.
              </span>
            </li>
          </ul>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            By using our services, you acknowledge and consent to the use of your information as outlined in this Privacy Policy. We are committed to protecting your privacy rights and complying with all applicable data protection regulations.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">4. Data Sharing and Disclosure</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            At Dr. Code, we prioritize your privacy and strive to limit data sharing to the bare minimum necessary to provide and enhance our services. We are committed to ensuring that any data sharing is essential, carefully managed, and always in alignment with your best interests. Our goal is to safeguard your personal and sensitive information, maintaining strict confidentiality throughout.
          </p>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">We may share your data with third parties only under specific circumstances, such as:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Service Providers:</span> We may engage trusted third-party service providers to perform certain functions on our behalf, such as hosting services, payment processing, analytics, or customer support. These providers are granted access only to the information necessary to perform their designated tasks and are contractually obligated to protect your data and not use it for any other purposes.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Legal Requirements:</span> In certain situations, we may be required to disclose your information in response to lawful requests, such as court orders, legal processes, or regulatory obligations. We comply with applicable laws and regulations and will only share information to the extent necessary to fulfill these legal obligations.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Protecting Rights and Security:</span> We may disclose data if we believe it is necessary to protect our rights, your safety, or the safety of others, investigate fraud, or respond to a government request.
              </span>
            </li>
          </ul>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            We want to make it clear that Dr. Code does not access, store, or share your entire codebase or project files. When you submit a pull request (PR) for review, we only access the specific code associated with that particular PR for analysis and feedback. Our review process is limited to this specific submission, and we do not retain or distribute the code beyond what is required for this purpose.
          </p>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            We do not share your code with any external entities beyond the secure environment necessary for our review, and no third-party has access to your code unless explicitly agreed upon by you. Dr. Code maintains strict controls and confidentiality measures to ensure that your intellectual property remains secure, and we are committed to upholding the highest standards of data protection for all our users.

          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">5. Third-Party Services and APIs (Usage of OpenAI API)</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            To enhance the functionality of Dr. Code, we integrate with third-party services, including the OpenAI API, which enables advanced features like code review, analysis, and language processing. These integrations are carefully selected to provide you with the best experience and ensure that any data shared is secure and managed responsibly.
          </p>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            When you interact with features powered by the OpenAI API, it’s important to know that OpenAI adheres to strict data handling policies. Specifically, OpenAI does not use API data to train or improve their models unless explicit permission is granted. This means that any information or code snippets you provide via our service are not used by OpenAI for training their models, ensuring that your data remains private and secure.
          </p>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            We take user privacy and data security seriously. The third-party services we use, including OpenAI, are committed to protecting your information. OpenAI has robust privacy and security measures in place to safeguard your data, which you can review in their privacy policy.
          </p>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">For further information on how OpenAI handles data, you can visit their privacy policy here: <a href="https://openai.com/enterprise-privacy/" className="text-[#7649d9]"> OpenAI Privacy Policy.</a>
          </h3>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            By using Dr. Code, you consent to our use of the OpenAI API under these conditions. We are committed to partnering only with third parties who demonstrate the highest standards of data protection to ensure that your information is always treated with care and respect.

          </p>


        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">6. Data Retention and Deletion</h2>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">At Dr. Code, we retain user data only for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy, comply with legal obligations, resolve disputes, and enforce our agreements. Specifically:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Account Information:</span> We retain your account information for as long as your account is active or as needed to provide you with our services.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Transaction Data:</span> Records of financial transactions, such as payment details, are retained to comply with legal, regulatory, or accounting requirements.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Code Submissions and Analysis Data:</span> Any code or information submitted for analysis and review will be retained only for the duration needed to complete the review process. After the analysis, we do not store your code unless it is part of ongoing support or an active project.
              </span>
            </li>
          </ul>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            We regularly review the data we hold and securely delete or anonymize information that is no longer needed, using industry-standard practices to protect your privacy during the deletion process.
          </p>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            You have the right to request the deletion of your data at any time. Upon receiving your request, we will delete or anonymize your personal information from our systems within a reasonable timeframe, except for any information that we are legally required to retain or that is necessary for legitimate business purposes.
          </p>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Here’s how we handle deletion requests:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">User Request:</span> You can request the deletion of your personal information by contacting us directly. We will verify your identity to protect your privacy and ensure the security of your data before processing the deletion request.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Account Closure:</span> If you choose to close your Dr Code account, we will initiate the deletion of your data, including account details, code submissions, and any associated personal information. Some data may be retained temporarily for backup, audit, or legal compliance purposes, but it will be fully deleted as soon as permissible.
              </span>
            </li>
          </ul>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            Please note that residual copies of your information may remain on backup media for a limited period due to technical constraints, but we ensure that these backups are stored securely and are not accessible as part of our operational systems.
          </p>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            By using Dr. Code, you acknowledge our data retention and deletion practices, which are designed to respect your privacy while complying with relevant laws and regulations.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">7.Security Measures</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            At Dr. Code, we prioritize the security of your data and implement a range of measures to safeguard it. We are committed to protecting your information from unauthorized access, alteration, disclosure, or destruction.
          </p>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Here’s an overview of our security practices:
          </h3>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">a. Overview of Data Security Protocols</h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Encryption:</span> We use industry-standard encryption protocols to protect your data during transmission and storage. This includes SSL/TLS encryption to secure data as it travels over the internet and advanced encryption standards to protect data at rest on our servers.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Access Control:</span> We implement strict access control measures to ensure that only authorized personnel have access to your information. User access is based on the principle of least privilege, which means that employees and service providers only have access to the minimum amount of data necessary to perform their duties. Additionally, all access is monitored and logged to prevent unauthorized access.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Authentication and Password Security:</span> We require strong password practices and offer two-factor authentication (2FA) for enhanced account security. User accounts are protected by hashed and salted passwords, meaning that even in the unlikely event of a data breach, your passwords remain secure.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Data Anonymization:</span> Where possible, we anonymize or pseudonymize data to further protect user privacy. This minimizes the risk of identifying individuals based on stored data, even if it were to be accessed without authorization.
              </span>
            </li>
          </ul>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">b. Regular Audits and Security Reviews</h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Internal Audits:</span> We conduct regular internal audits to assess our security practices and ensure compliance with our privacy policy and industry standards. These audits include a review of our systems, processes, and access controls, identifying any areas for improvement to ensure ongoing protection.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Third-Party Security Assessments:</span> We aim to periodically engage with third-party security experts to conduct vulnerability assessments and penetration testing. This helps us identify and address any potential weaknesses in our systems before they can be exploited.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Continuous Monitoring:</span> Our systems are continuously monitored for unusual activity or potential security threats. We employ automated tools that provide real-time alerts in the event of suspicious behavior, enabling us to respond promptly to potential security incidents.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Incident Response Plan:</span> In the unlikely event of a data breach, we will conduct a thorough investigation to mitigate the impact and prevent such future incidents.
              </span>
            </li>
          </ul>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            By employing these security measures, Dr. Code aims to protect your data with the highest level of care. We are dedicated to continuously improving our security protocols to meet emerging threats and to provide you with a safe and secure experience.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">8.User Rights and Choices</h2>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Dr. Code is committed to giving users control over their personal data in accordance with applicable data protection laws. Below is an overview of your rights:
          </h3>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">a. Rights to Access, Rectify, Delete, or Restrict Data
          </h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Access:</span> Request access to your personal information, and we will provide it in a commonly used format.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Rectification:</span> Request corrections to any inaccurate or incomplete information.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Deletion:</span> Request the deletion of your data, unless retention is required for legal purposes.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Restriction:</span> Request to restrict processing in certain circumstances, such as contesting data accuracy.
              </span>
            </li>
          </ul>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">b. Opt-Out Options for Marketing or Other Communications
          </h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Marketing Communications:</span> Opt out of promotional emails anytime by clicking the "unsubscribe" link or contacting us directly.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Service-Related Communications:</span> Important service messages may still be sent even if you opt out of marketing communications.
              </span>
            </li>

          </ul>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">c. Information on How to Exercise These Rights
          </h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Exercising Your Rights:</span> Contact our support team at <a href="mailto:hello@drcode.ai" className="text-blue-500 hover:underline">hello@drcode.ai</a>. We may require identity verification.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Response Time:</span> We aim to respond to requests within 30 days, with updates for complex requests.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Fees:</span> No fees for legitimate requests, but we may charge for excessive or unfounded requests.
              </span>
            </li>

          </ul>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">By providing these rights, Dr. Code ensures you maintain control over your personal information and is dedicated to respecting your privacy.
          </h3>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">9.Cookies and Tracking Technologies</h2>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">At Dr. Code, we employ cookies and similar tracking technologies to enhance user experience, analyze website traffic, and personalize content. This section outlines the types of cookies we use, their purposes, and how users can manage their cookie preferences.
          </h3>
          <h4 className="font-bold mb-2 text-[#B2B2C1]">a. Types of Cookies Used and Their Purposes</h4>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Essential Cookies:</span> These cookies are crucial for the proper functioning of our website. They enable basic functionalities such as page navigation, access to secure areas of the website, and the ability to use various features. Without these cookies, the website cannot operate as intended.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Performance Cookies:</span> Performance cookies collect data about how visitors use our website. They provide insights into which pages are visited most often, how users navigate through the site, and whether they encounter any errors. This information helps us improve website performance and enhance the user experience.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Functional Cookies:</span> These cookies enable our website to remember user preferences and choices, such as login information, language selection, and other customizations. By using functional cookies, we can offer a more personalized experience tailored to your needs.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Targeting/Advertising Cookies:</span> Targeting cookies track users' online behavior to deliver advertisements relevant to their interests. These cookies may be used to limit the number of times you see an ad and help measure the effectiveness of advertising campaigns.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Analytics Cookies:</span> Analytics cookies are used to collect information about how visitors interact with our website. This includes data on the number of visitors, the pages they visit, and the time spent on each page. This information helps us analyze trends and improve our services.
              </span>
            </li>

          </ul>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Users have the option to manage or disable cookies through their browser settings. Below are some ways to control cookie preferences:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Browser Settings:</span> Most web browsers allow users to control cookie settings. You can set your browser to refuse cookies or alert you when cookies are being sent. The method for doing this varies from browser to browser, so please refer to your browser's help section for specific instructions.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Opting Out:</span> You can also opt out of cookies from third-party services and advertising networks by visiting the Network Advertising Initiative's opt-out page or the Digital Advertising Alliance's opt-out page. This allows you to control the collection of your information for advertising purposes.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Cookie Consent Banner:</span> When you first visit our website, you will see a cookie consent banner. This banner provides you with information about our use of cookies and allows you to accept or decline non-essential cookies. You can change your cookie preferences at any time through the cookie settings in the consent banner.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Impact of Disabling Cookies:</span> Please note that disabling cookies may impact the functionality of our website and your user experience. Some features may not work as intended, and you may not be able to access certain parts of our website.
              </span>
            </li>

          </ul>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">By using our website, you consent to the use of cookies as outlined in this policy. If you have any questions about our use of cookies or our privacy practices.
          </h3>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">10.Children’s Policy</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            The Services are general audience and intended for users 13 and older. We do not knowingly collect Personal Information from anyone younger than age 13.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">11.Policy Updates and Changes</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            At Dr. Code, we may update our Privacy Policy from time to time to reflect changes in our practices or applicable laws.
            We will review and revise this Privacy Policy periodically. Significant changes will be made public on our website and will take effect upon posting.
            Users are encouraged to review the Privacy Policy regularly to stay informed about our data practices.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">12. Contact Information</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            If you have any questions, concerns, or requests regarding this Privacy Policy or your personal data, please feel free to reach out to us:
          </p>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">You can contact us via email or our contact form available on our website. We strive to respond to all inquiries promptly.
            If applicable, you can reach our Data Protection Officer at:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Email:</span> <a href="mailto:hello@drcode.ai" className="text-blue-500 hover:underline">hello@drcode.ai</a>
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Phone:</span> <a href="tel:+919999410384" className="text-blue-500 hover:underline">+91 99994 10384</a>
              </span>
            </li>

          </ul>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">13. Legal Compliance</h2>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              {/* <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" /> */}
              <span>
                <span className="font-[800]">1. Intellectual Property Rights:</span>
                All content on Dr. Code, including but not limited to text, articles, logos, graphics, images, and videos, is the property of Dr. Code or its content providers and is protected by copyright and intellectual property laws. You may access the content for personal, non-commercial use only. Any reproduction, distribution, or use of content beyond this scope requires express written permission from Dr. Code.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              {/* <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" /> */}
              <span>
                <span className="font-[800]">2. Trademarks:</span>
                Unauthorized use of any Dr. Code trademark, service mark, or logo is prohibited and may be a violation of applicable trademark laws.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              {/* <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" /> */}
              <span>
                <span className="font-[800]">3. Copyrights:</span>
                The Website is protected by applicable copyright laws. Except for your authorized use as described above, you may not modify, reproduce, or distribute the content, design, or layout of the Website, or individual sections of the content, design, or layout of the Website, without Dr. Code’s express prior written permission.
              </span>
            </li>

            <li className="flex items-start mb-2 text-[#B2B2C1]">
              {/* <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" /> */}
              <span>
                <span className="font-[800]">4. Notice and Procedures for Claims of Infringement:</span>
                If you believe that your work has been copied in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please provide the following information to us via our contact form:
                <br />
                An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright or other intellectual property interest that is claimed to be infringed.
                <br />
                a) A description of the copyrighted work or other intellectual property that you claim has been infringed.
                <br />
                b) A description of where the material that you claim is infringing is located on the Site (providing URL(s) in the body of the communication is the best way to help Dr. Code locate content quickly).
                <br />
                c) Your name, address, telephone number, and e-mail address.
                <br />
                d) A signed statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                <br />
                e) A statement by you, made under penalty of perjury, that the information provided in your Notice is accurate and that you are the copyright or intellectual property owner or licensee or authorized to act on the copyright or intellectual property owner’s or licensee’s behalf.
                <br />
                f) You can write to us at:
                <br />
                Email: <a href="mailto:hello@drcode.ai" className="text-blue-500 hover:underline">hello@drcode.ai</a>
                <br />
                (Please put “Copyright Infringement” in the subject line)
                <br />
                g) Dr. Code may, in its sole discretion, disable and/or terminate use of or access to the Website by users who infringe the intellectual property of others or of Dr. Code.
              </span>
            </li>

          </ul>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">14. User Outside India</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
            Dr. Code , Inc is based in India, and your use of the Services implies consent to the transfer of information to countries outside your country of residence, including India.
          </p>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">15. What Personal Data is Not Collected?</h2>

          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Dr. Code does not collect the following types of personal data:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                Sensitive personal information such as health records, racial or ethnic origin, political opinions, or religious beliefs.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                Financial information, including credit card details, unless explicitly provided for transaction purposes.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                Any personal data of minors under the age of 18 without parental consent.
              </span>
            </li>
          </ul>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">16. With Whom Does Dr. Code Share My Personal Data?</h2>
          <h3 className="text-lg font-normal mb-2 text-[#B2B2C1] text-justify">Dr. Code may share your personal data with the following entities:
          </h3>
          <ul className="list-none pl-0 text-justify">
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Service Providers:</span> Third-party vendors who assist in operating our website, conducting business, or providing services to you, provided they agree to keep this information confidential.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Legal Authorities:</span> If required by law, we may disclose your information to comply with legal obligations or protect our rights.
              </span>
            </li>
            <li className="flex items-start mb-2 text-[#B2B2C1]">
              <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
              <span>
                <span className="font-[800]">Business Transfers:</span> In the event of a merger, acquisition, or sale of all or a portion of our assets, your data may be transferred as part of that transaction.
              </span>
            </li>
          </ul>
        </div>
        <div className=" w-[100%]">
          <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">Conclusion</h2>
          <p className="mb-2 text-[#B2B2C1] text-justify">
          At Dr. Code, your privacy is our priority. We are committed to protecting your personal data and ensuring transparency about how it is used. By using our services, you agree to the terms outlined in this Privacy Policy. We encourage you to review this document regularly and contact us if you have any questions or concerns. Thank you for trusting us with your information.
          </p>
        </div>
        {/* {secondSection.map((data: Point, index: number) => (
          <Content
            key={index + splitIndex}
            title={data.title}
            subtext={data.subtext}
            para={data.para}
            point1={data.point1}
            point2={data.point2}
          />
        ))} */}
      </div>
    </div>
  );
};

export default Details;
