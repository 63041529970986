import { IoIosGitBranch } from "react-icons/io";
import { FaGithub, FaBitbucket } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { IoFilter } from "react-icons/io5";
import { FiSearch } from "react-icons/fi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoMdClose } from "react-icons/io";
import SortMenu from "./SortMenu";
import IssueToggle from "./IssueToggle";
import { splitAndReduceText } from "../../../../../Utils/Services/Interceptor";
import { getDataFromLocalStorage } from "../../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS, UserData } from "../../../../../Utils/Constants/localStorageDataModels";
import AnimationLoader from "../../../../Common/AnimationLoader";
import Empty from "./Empty";

interface Issue {
  id: string;
  count: string;
  type: string;
  level: string;
  status: string;
  project: {
    id: string;
    platform: string | null;
    slug: string;
    name: string;
  };
  title: string;
  firstSeen: number;
  lastSeen: number;
  priority: any;
  branch: string;
}
interface repoDetails {
  repo_name?: string;
  branch_name?: string;
}
interface IssueListProps {
  project_id: number;
  setIssueID: (id: number) => void;
  issueID: number;
}
const IssueList: React.FC<IssueListProps> = ({
  project_id,
  setIssueID,
  issueID,
}) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortParam, setSortParam] = useState<string>("last_seen");
  const [issueType, setIssueType] = useState<string>("unresolved");
  const [showSortMenu, setShowSortMenu] = useState<boolean>(false);
  const [showSearchInput, setShowSearchInput] = useState<boolean>(false);
  const API_URL = process.env.REACT_APP_API_URL;
  const [issues, setIssues] = useState<Issue[]>([]);
  // const { userData } = useUser();
  const [loading, setLoading] = useState<boolean>(true);
  const [repoDetails, setRepoDetails] = useState<repoDetails>();
  const [startDate, setStartDate] = useState<number>(0);
  const [endDate, setEndDate] = useState<number>(0);
  const [isDateUpate, setIsDateUpdate] = useState<Boolean>(false);

  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";

  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const storedUserData = getDataFromLocalStorage<UserData>(
      LOCAL_STORAGE_DATA_KEYS.USER_DATA
    );
    if (storedUserData && typeof storedUserData === "object") {
      setUserData(storedUserData as UserData);
    }
  }, []);

  const getAuthHeaders = () => ({
    Authorization: `${getDataFromLocalStorage(
      LOCAL_STORAGE_DATA_KEYS.SESSION_ID
    )}`,
  });

  const fetchIssue = async () => {
    try {
      setLoading(true);

      // Start building the URL with required parameters
      let url = `${API_URL}bucket=pulse&operationName=issues&project_id=${project_id}&type=${issueType}&sort=${sortParam}&orgName=${encodeURIComponent(
        selectedOrg
      )}`;

      if (startDate > 0) {
        url += `&start=${startDate}`;
      }

      if (endDate > 0) {
        url += `&end=${endDate}`;
      }

      const response = await axios.get(url, { headers: getAuthHeaders() });
      setIssues(response.data.data.issues);
      console.log(
        "issues data ",
        response.data.data.issues,
        "length ",
        response.data.data.issues.length
      );
      if (response.data.data.issues.length === 0) {
        console.log("issue length is zero then resolved will show");
        setIssueType("resolved");
      }

      setIssueID(response.data.data.issues[0]?.id);
      setRepoDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching log data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedOrg != "No organization") {
      fetchIssue();
    }
  }, [project_id, issueType, sortParam, isDateUpate, selectedOrg]);

  const handleBack = () => {
    navigate("/pulse");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const toggleSortMenu = () => {
    setShowSortMenu(!showSortMenu);
  };
  const toggleSearchInput = () => {
    setShowSearchInput(!showSearchInput);
    if (showSearchInput) {
      setSearchQuery("");
    }
  };

  const handleSortChange = (param: string) => {
    if (param === "unresolved" || param === "resolved") {
      setIssueType(param);
    } else {
      setSortParam(param);
    }
    setShowSortMenu(false);
  };
  const filteredIssues = issues.filter((issue) =>
    issue?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  const handleSetIssueID = (id: number) => {
    setIssueID(id);
  };

  const formatDate = (timestamp: number): string => {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long", // Month name
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // AM/PM format
    };

    return new Date(timestamp * 1000)
      .toLocaleString("en-US", dateOptions)
      .replace(",", " at");
  };
  const formatLastSeenDate = (lastSeenTimestamp: number): string => {
    const lastSeenDate = new Date(lastSeenTimestamp * 1000);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - lastSeenDate.getTime(); // difference in milliseconds
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24)); // convert to days

    if (daysAgo === 0) return "Today";
    if (daysAgo === 1) return "1 day ago";
    return `${daysAgo} days ago`;
  };
  {
    console.log("repoDetails ", repoDetails?.branch_name);
  }
  return (
    <div className="w-[24%]  pb-4 text-[#D1D1E3] h-screen">
      <div className="mt-3 mx-2 flex justify-start gap-4">
        <div onClick={handleBack} className="cursor-pointer">
          <FaArrowLeft className="text-xl" />
        </div>
        <div>
          <h1 className="">{repoDetails?.repo_name}</h1>
          <div className="flex flex-col justify-between items-start  text-center mb-4">
            <div className="flex gap-1 items-center">
              {userData?.carrier == "github" ? <FaGithub className="text-lg text-[#E2E2ED] " /> :
                userData?.carrier == "bitbucket" ? <FaBitbucket className="text-lg text-[#E2E2ED] " /> : <></>}
              <h2 className="text-[#777781] font-normal text-[14px]">
                {selectedOrg}
              </h2>
            </div>
            <div className="flex items-center mt-1">
              <IoIosGitBranch className="text-[#777781] group-hover:text-white" />
              <span className="text-[#777781] text-[13px] group-hover:text-white ">
                {repoDetails?.branch_name && repoDetails.branch_name.length > 10
                  ? "branch"
                  : splitAndReduceText(repoDetails?.branch_name ?? "", 3)}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#0D0D22] rounded-t-[12px] border border-[#1B1B41] py-4 px-3 flex justify-between items-center">
        {showSearchInput ? (
          <div className="flex items-center bg-[#0D0D22] border border-[#2E2E60] text-[#B2B2C1] p-1 text-[14px] placeholder-[##C0C4CE] w-[90%] rounded-lg">
            <FiSearch className="text-[#B2B2C1] text-[16px] mx-2" />
            <input
              type="text"
              placeholder="Search logs"
              value={searchQuery}
              onChange={handleSearch}
              className="bg-transparent outline-none text-[#C0C4CE] w-full"
            />
            <IoMdClose
              className="text-[#B2B2C1] text-[16px] mx-2 cursor-pointer"
              onClick={toggleSearchInput}
            />
          </div>
        ) : (
          <div className="w-full flex justify-between items-center">
            <span className="text-[#B2B2C1] text-[14px] font-bold">
              Issues ({filteredIssues.length})
            </span>
            <FiSearch
              className="text-[#B2B2C1] text-[16px] mr-2 cursor-pointer"
              onClick={toggleSearchInput}
            />
          </div>
        )}
        <div className="flex gap-3 items-center relative">
          <IoFilter
            className="text-[#B2B2C1] text-[15px] cursor-pointer"
            onClick={toggleSortMenu}
          />
          {showSortMenu && (
            <SortMenu
              onSortChange={handleSortChange}
              currentSort={sortParam}
              currentType={issueType}
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setIsDateUpdate={setIsDateUpdate}
            />
          )}
        </div>
      </div>

      <div className="bg-[#0D0D22] h-[80%] rounded-b-[12px] overflow-auto codepluse-scrollbar border-x border-[#1B1B41]">
        {loading ? (
          <AnimationLoader />
        ) : filteredIssues.length > 0 ? (
          filteredIssues.map((issue) => (
            <div
              key={issue.id}
              className={`p-2 group  border-b border-[#1C1C1F] cursor-pointer ${issueID == Number(issue?.id)
                  ? "bg-[#131330] mx-[6px] my-[10px] rounded-[10px]"
                  : "hover:bg-[#131330] hover:rounded-[10px] bg-[#0D0D22]"
                }`}
              onClick={() => handleSetIssueID(Number(issue?.id))}
            >
              <h3 className="text-[14px] text-[#B2B2C1] group-hover:text-white">
                {splitAndReduceText(issue?.title, 3)}
              </h3>
              <div className="flex items-center mt-1">
                <div
                  className={`w-2 h-2 rounded-full mr-1 ${issue?.status === "unresolved"
                      ? "bg-red-500"
                      : "bg-green-500"
                    }`}
                />
                <IoIosGitBranch className="mr-1 text-[#777781] group-hover:text-white" />
                <span className="text-[#777781] text-[13px] group-hover:text-white">
                  {repoDetails?.branch_name &&
                    repoDetails.branch_name.length > 10
                    ? "branch"
                    : splitAndReduceText(repoDetails?.branch_name ?? "", 3)}
                </span>

                <div className="ml-5">
                  <IssueToggle
                    projectId={project_id}
                    issueId={issueID}
                    initialStatus={issue?.status}
                  />
                </div>
              </div>
              <p className="text-[11px] text-[#9494A1] mt-1 group-hover:text-white">
                {formatDate(issue.firstSeen)} ,{" "}
              </p>
              <p className="text-[11px] text-[#9494A1]  group-hover:text-white">
                {formatLastSeenDate(issue.lastSeen)}
              </p>
            </div>
          ))
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
};

export default IssueList;
