// src/Utils/Constants/RouteConstants.ts

export const HOME = "/";
export const CONTACT_US = "/contact-us"
export const TNC = "/terms-and-conditions";
export const Privacy_Policy = "/privacy-policy";
export const PRICING='/pricing'
export const LOGIN = "/login";
export const NOT_FOUND = "*";
export const LAYOUT = "/";
export const CALLBACK = "/callback";

export const DASHBOARD = "/home";
export const CODE_REVIEW = "/review";

export const CODE_PULSE = "/pulse";
export const CHAT_SUPPORT = "/assist";
export const CODE_TEST = "/codetest";
export const SETTINGS = "/home";

export const CODE_PULSE_CONFIGURE = "/pulse/configure";
export const CODE_PULSE_REPOSITORIES = "/repositories";
export const CODE_PULSE_REPOSITORY_LOG_LIST = "/pulse/:secureId";
