import React from "react";
import HeroTxt from "../Common/HeroTxt";

const PriceTable = () => {
  return (
    <div className="mt-28">
      <HeroTxt
        title="How DrCode Stands Out"
        para="Comparing drcode with other tools"
      />
    </div>
  );
};

export default PriceTable;
