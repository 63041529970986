import React, { useState, useEffect, useCallback } from "react";
import Chat from "./components/Chat";
import axios from "axios";
import ChatThreadList from "./components/ChatThreadList";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../Utils/Constants/localStorageDataModels";
import { getDataFromLocalStorage } from "../../../Utils/globalUtilities";
import AnimationLoader from "../../Common/AnimationLoader";

const API_URL = process.env.REACT_APP_API_URL;

interface Thread {
  threadid: string;
  title: string;
}
interface Message {
  id: number;
  sender: "user" | "drcode";
  text: string;
}
const ChatWithAI: React.FC = () => {
  const [threads, setThreads] = useState<Thread[]>([]);
  const [selectedThreadId, setSelectedThreadId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreatingNew, setIsCreatingNew] = useState<boolean>(false);
  const [notNew, setNotNew] = useState<boolean>(false);
  const [messages, setMessages] = useState<Message[]>([]);
  console.log("initizlaiy,", "is create", isCreatingNew, "no new", notNew);
  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";
  const token: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SESSION_ID) || "";

  const fetchThreads = useCallback(async () => {
    try {
      const apiURL = `${API_URL}bucket=chat&operationName=conversations&limit=10&offset=0&orgName=${encodeURIComponent(
        selectedOrg
      )}`;
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: `${token}`,
        },
      });
      setThreads(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching threads:", error);
      setLoading(false);
    }
  }, [selectedOrg, token]);

  useEffect(() => {
    fetchThreads();
  }, [fetchThreads]);

  const handleThreadSelect = (threadId: string) => {
    setSelectedThreadId(threadId);
    setIsCreatingNew(false);
    setNotNew(true);

    console.log(
      "handl thread selected,",
      "is create",
      isCreatingNew,
      "no new",
      notNew
    );
  };

  const handleStartNew = () => {
    setSelectedThreadId("");
    setIsCreatingNew(false);
    setNotNew(false);
    setMessages([]);
    console.log("handl new,", "is create", isCreatingNew, "no new", notNew);
  };

  const updateThreadList = async (newThread: Thread) => {
    await fetchThreads();
    setSelectedThreadId(newThread.threadid);
    setIsCreatingNew(false);
  };

  return (
    <div className="w-full flex bg-[#080814] h-screen">
      {loading ? (
        <div className="w-full h-screen flex justify-center items-center">
          <AnimationLoader />
        </div>
      ) : (
        <div className="w-full flex justify-center ">
          <ChatThreadList
            threads={threads}
            onSelectThread={handleThreadSelect}
            selectedThreadId={selectedThreadId}
            onStartNew={handleStartNew}
            isCreatingNew={isCreatingNew}
          />
          <Chat
            threadId={selectedThreadId}
            setSelectedThreadId={setSelectedThreadId}
            onUpdateThreadList={updateThreadList}
            isCreatingNew={isCreatingNew}
            setIsCreatingNew={setIsCreatingNew}
            notNew={notNew}
            messages={messages}
            setMessages={setMessages}
          />
        </div>
      )}
    </div>
  );
};

export default ChatWithAI;
