import React, {
  useState,
  useEffect,
  useRef,
  FormEvent,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import submitButton from "../../../../../Assets/Svg/submitButton.svg";
import Shutter from "./Shutter";
import CodePulseLogo from "../../../../../Assets/Svg/codepulseLogo.svg";
import userLogo from "../../../../../Assets/Svg/userLogo.svg";
import { IoCopyOutline } from "react-icons/io5";
import { TfiReload } from "react-icons/tfi";
// import { getSessionID, useUser } from "../../../../../UserContext";
import MarkdownConverter from "../../../../Common/MarkdownConverter";
import axios from "axios";
import Spinner from "../../../../Common/Spinner";
import MarkdownPreview from "@uiw/react-markdown-preview";
import "./ChatInterface.css";
import { toast } from "react-toastify";
import { getDataFromLocalStorage } from "../../../../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../../../../Utils/Constants/localStorageDataModels";
import AnimationLoader from "../../../../Common/AnimationLoader";
import noIssue from "../../../../../Assets/Svg/NoIssue.svg";
import FormattedDisplay from "../../../ChatWithAI/components/FormattedDisplay";
import AutoResizeTextarea from "../../../ChatWithAI/components/AutoResizeTextarea";

interface Message {
  id: number;
  sender: "user" | "drcode";
  text: string;
}

interface ApiResponse {
  message: string;
  data: Array<{
    drcode?: string;
    user?: string;
  }>;
}
type OrgType = { org_name: string };

const ChatInterface: React.FC<{
  setOpenLog: (open: boolean) => void;
  logData: any;
  loading: Boolean;
  issueID: number;
  project_id: number;
}> = ({ setOpenLog, logData, loading, issueID, project_id }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [inputVisible, setInputVisible] = useState(true);
  // const { userData } = useUser();
  const issue_id = issueID;
  // const issue_id = 27;
  const projectID = project_id;
  const getAuthHeaders = () => ({
    Authorization: `${getDataFromLocalStorage(
      LOCAL_STORAGE_DATA_KEYS.SESSION_ID
    )}`,
  });
  const selectedOrg: string =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";

  const fetchConversation = async (
    project_id: number,
    issue_id: number
  ): Promise<ApiResponse | undefined> => {
    if (project_id && issue_id) {
      try {
        const response = await axios.post<ApiResponse>(
          `${API_URL}bucket=pulse&operationName=conversation&orgName=${encodeURIComponent(
            selectedOrg
          )}`,
          {
            project_id,
            issue_id,
          },
          {
            headers: {
              ...getAuthHeaders(),
            },
          }
        );
        return response.data;
      } catch (error) {
        console.error("Error in getting conversation data:", error);
        throw error;
      }
    } else {
      return undefined;
    }
  };

  useEffect(() => {
    if (issueID != -1) {
      const fetchInitialConversation = async () => {
        setIsInitialLoading(true);
        try {
          const data = await fetchConversation(projectID, issue_id);
          const formattedMessages: Message[] =
            data?.data?.map((item, index) => ({
              id: index,
              sender: item.drcode ? "drcode" : "user",
              text: item.drcode || item.user || "",
            })) || [];
          setMessages(formattedMessages);
        } catch (err) {
          setError("Failed to fetch initial conversation");
        } finally {
          setIsInitialLoading(false);
        }
      };

      fetchInitialConversation();
    }
  }, [projectID, issue_id]);

  const handleSubmit = async (e?: React.FormEvent) => {
    e?.preventDefault();
    if (!inputMessage.trim() || isLoading) return;

    const userMessage: Message = {
      id: messages.length,
      sender: "user",
      text: inputMessage,
    };

    setMessages((prevMessages) => [
      ...prevMessages,
      userMessage,
      {
        id: prevMessages.length + 1,
        sender: "drcode",
        text: "Hold tight, brilliance is on its way...",
      },
    ]);
    setInputMessage("");
    setIsLoading(true);
    setInputVisible(false);

    try {
      const response = await fetch(
        `${API_URL}bucket=pulse&operationName=chat&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(),
          },
          body: JSON.stringify({
            issue_id: issue_id,
            query: inputMessage,
          }),
        }
      );

      if (!response.body) throw new Error("No response body");

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      let accumulatedResponse = "";
      let isFirstChunk = true;
      let lineBuffer = "";

      const updateMessageWithNewLine = (newLine: string) => {
        setMessages((prevMessages) => {
          const lastMessage = prevMessages[prevMessages.length - 1];
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              text: lastMessage.text + newLine + "\n",
            },
          ];
        });
      };

      while (!done) {
        const { done: readerDone, value } = await reader.read();
        done = readerDone;

        const chunk = decoder.decode(value, { stream: true });
        const cleanChunk = chunk
          .split("\n")
          .map((line) => line.replace(/^data: /, "").trim())
          .join("\n");

        lineBuffer += cleanChunk;

        const lines = lineBuffer.split("\n");
        lineBuffer = lines.pop() || ""; // Keep the last incomplete line in the buffer

        if (isFirstChunk) {
          // Replace "Hold tight..." with the first line
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            {
              id: prevMessages.length - 1,
              sender: "drcode",
              text: lines[0] + "\n",
            },
          ]);
          isFirstChunk = false;
          lines.shift(); // Remove the first line as it's already added
        }

        // Add each complete line to the message
        for (const line of lines) {
          await new Promise((resolve) => setTimeout(resolve, 10)); // Add a small delay for visual effect
          updateMessageWithNewLine(line);
        }

        accumulatedResponse += cleanChunk;
      }

      // Add any remaining text in the lineBuffer
      if (lineBuffer) {
        updateMessageWithNewLine(lineBuffer);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        {
          id: prevMessages.length - 1,
          sender: "drcode",
          text: "An error occurred while fetching the response.",
        },
      ]);
    } finally {
      setIsLoading(false);
      setInputVisible(true);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied!");
  };

  const handleRegenerate = async (messageId: number) => {
    const messageToRegenerate = messages.find((msg) => msg.id === messageId);
    console.log("Message to regenerate:", messageToRegenerate);

    if (!messageToRegenerate || messageToRegenerate.sender !== "drcode") {
      console.log("Invalid message for regeneration");
      return;
    }

    let userMessage;
    if (messageId === 0) {
      userMessage = {
        text: "Analyze the issue properly and give me with potentially different wording, examples, or additional insights.",
      };
      console.log("Using default query for initial DrCode message");
    } else {
      const userMessageIndex = messages.findIndex(
        (msg) => msg.id === messageId - 1
      );
      console.log("User message index:", userMessageIndex);

      if (userMessageIndex === -1) {
        console.log("Corresponding user message not found");
        return;
      }

      userMessage = messages[userMessageIndex];
    }

    setIsLoading(true);
    setInputVisible(false);

    // Update the message to show it's regenerating
    setMessages((prevMessages) => {
      const newMessages = prevMessages.map((msg) =>
        msg.id === messageId
          ? { ...msg, text: "Regenerating response..." }
          : msg
      );
      return newMessages;
    });

    try {
      const response = await fetch(
        `${API_URL}bucket=pulse&operationName=chat&orgName=${encodeURIComponent(
          selectedOrg
        )}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...getAuthHeaders(),
          },
          body: JSON.stringify({
            issue_id: issue_id,
            query: userMessage.text,
          }),
        }
      );

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let done = false;
      let accumulatedResponse = "";
      let lineBuffer = "";
      let isFirstChunk = true;

      const updateMessageWithNewLine = (newLine: string) => {
        setMessages((prevMessages) => {
          return prevMessages.map((msg) =>
            msg.id === messageId
              ? { ...msg, text: msg.text + newLine + "\n" }
              : msg
          );
        });
      };

      while (!done) {
        const { done: readerDone, value } = await reader.read();
        done = readerDone;

        const chunk = decoder.decode(value, { stream: true });
        const cleanChunk = chunk
          .split("\n")
          .map((line) => line.replace(/^data: /, "").trim())
          .join("\n");

        lineBuffer += cleanChunk;

        const lines = lineBuffer.split("\n");
        lineBuffer = lines.pop() || ""; // Keep the last incomplete line in the buffer

        if (isFirstChunk) {
          // Replace "Hold tight..." with the first line
          setMessages((prevMessages) => {
            return prevMessages.map((msg) =>
              msg.id === messageId ? { ...msg, text: lines[0] + "\n" } : msg
            );
          });
          isFirstChunk = false;
          lines.shift(); // Remove the first line as it's already added
        }

        // Add each complete line to the message
        for (const line of lines) {
          await new Promise((resolve) => setTimeout(resolve, 50)); // Add a small delay for visual effect
          updateMessageWithNewLine(line);
        }

        accumulatedResponse += cleanChunk;
      }

      // Add any remaining text in the lineBuffer
      if (lineBuffer) {
        updateMessageWithNewLine(lineBuffer);
      }
    } catch (error) {
      console.error("Error regenerating message:", error);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.id === messageId
            ? {
                ...msg,
                text: "An error occurred while regenerating the response.",
              }
            : msg
        )
      );
    } finally {
      setIsLoading(false);
      setInputVisible(true);
      console.log("Regeneration process completed");
    }
  };
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line on Shift+Enter
        return;
      } else {
        // Submit on Enter without Shift
        e.preventDefault();
        handleSubmit(e as unknown as FormEvent<HTMLFormElement>);
      }
    }
  };
  // console.log("message ", messages);
  return (
    <div className="w-[75%] flex flex-col mt-5 rounded-xl relative h-full border-[#1B1B41]  border-y">
      <Shutter
        repoName="Sentry Error Capture Code"
        setOpenLog={setOpenLog}
        logData={logData}
        loading={loading}
      />
      <div
        ref={chatContainerRef}
        className="flex-grow overflow-y-auto p-4 bg-[#131330]  no-scrollbar pb-20" // Added padding bottom here
      >
        {isInitialLoading && (
          <div className="w-full h-screen bg-[#080814] flex justify-center items-center p-5">
            <div className="">
              <AnimationLoader />
            </div>
          </div>
        )}
        {messages.map((message) => (
          <div
            key={message.id}
            className={`mb-4 flex ${
              message.sender === "user" ? "justify-end" : "justify-start"
            }`}
          >
            <div className={`max-w-[80%]`}>
              <div
                className={`flex items-center mb-1 ${
                  message.sender === "user" ? "flex-row-reverse" : "flex-row "
                }`}
              >
                <img
                  src={message.sender === "user" ? userLogo : CodePulseLogo}
                  alt={message.sender === "user" ? "User" : "CodePulse"}
                  className={`w-4 h-4 ${message.sender === "user" && "ml-2"}`}
                />
                <div className="text-[12px] font-inter text-[#777781]">
                  {message.sender === "user" ? "You" : "DrCode"}
                </div>
              </div>
              <div
                className={`p-2 rounded-lg   text-[14px] ${
                  message.sender === "user"
                    ? "text-left text-[#E2E2ED]"
                    : "text-left bg-[#0A0A1A] text-[#D1D1E3]"
                }`}
              >
                {message.sender === "drcode" ? (
                  <MarkdownPreview
                    className="custom-markdown"
                    source={message.text}
                  />
                ) : (
                  <FormattedDisplay message={message} />
                )}
              </div>
              {message.sender === "drcode" && isLoading === false && (
                <div className="w-14 flex justify-start bg-[#06060D] gap-4 px-2 py-3 mt-2 rounded-lg">
                  <button
                    onClick={() => handleCopy(message.text)}
                    className="text-white"
                  >
                    <IoCopyOutline size={12} />
                  </button>
                  <button
                    onClick={() => handleRegenerate(message.id)}
                    className="text-white"
                  >
                    <TfiReload size={12} />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {!isInitialLoading && (
        <form
          onSubmit={handleSubmit}
          className="px-5 z-10 flex justify-between items-end pb-10 bg-[#131330]"
        >
          <AutoResizeTextarea
            value={inputMessage}
            onKeyDown={handleKeyDown}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Ask follow-up..."
            isInitialLoading={isLoading}
            handleSubmit={handleSubmit}
          />
        </form>
      )}
    </div>
  );
};

export default ChatInterface;
