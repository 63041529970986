import React from "react";

interface HeroTxtProps {
  title: string;
  para: string;
  subtext?: string;
}

const HeroTxt: React.FC<HeroTxtProps> = ({ title, para, subtext }) => {
  return (
    <div className="text-center px-5 md:text-nowrap md:px-[14%] my-5 ">
      <h1 className="text-4xl md:text-5xl font-bold mb-2 text-[#D1D1E3]">
        {title}
      </h1>
      <p className="text-sm px-10 md:px-0 md:text-lg text-[#9494A1] font-semibold">
        {para}
      </p>
      <p className="mb-8 text-sm px-10 md:px-0 md:text-lg text-[#9494A1] font-[200]">
        {subtext}
      </p>
    </div>
  );
};

export default HeroTxt;
