import React from "react";

interface Feature {
  name: string;
}

interface Tool {
  name: string;
  features: string[];
}

const features: Feature[] = [
  { name: "AI-Powered Code Review" },
  { name: "Real-time Feedback" },
  { name: "Production Issues Tracking" },
  { name: "Code Quality Score" },
  { name: "Supports Bitbucket Integration" },
  { name: "Automated Unit Testing" }
];

const tools: Tool[] = [
  {
    name: "DrCode",
    features: [
      "AI-Powered Code Review",
      "Real-time Feedback",
      "Production Issues Tracking",
      "Code Quality Score",
      "Supports Bitbucket Integration",
      "Automated Unit Testing"
    ],
  },
  {
    name: "CodeRabbit",
    features: ["AI-Powered Code Review", "Real-time Feedback"],
  },
  {
    name: "Testim",
    features: ["Automated Unit Testing"],
  },
  {
    name: "Sentry",
    features: ["Production Issues Tracking"],
  },
];

const FeatureComparisonTable: React.FC = () => {
  return (
    <div className="w-full overflow-x-scroll px-5 lg:px-0 no-scrollbar">
      <div className="min-w-max flex justify-center items-center">
        <table
          className="border-2 border-[#1B1B41] rounded-lg overflow-hidden border-collapse "
          style={{ borderSpacing: 0 }}
        >
          <thead>
            <tr>
              <th className="border-2 border-[#1B1B41] px-10 py-3"></th>
              {tools.map((tool) => (
                <th
                  key={tool.name}
                  className="border-2 border-[#1B1B41] px-10 py-3 font-bold"
                >
                  {tool.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {features.map((feature, rowIndex) => (
              <tr key={feature.name}>
                <td className="border-2 border-[#1B1B41] px-8 py-3 font-400 text-[14px] text-[#B2B2C1]">
                  {feature.name}
                </td>
                {tools.map((tool, colIndex) => (
                  <td
                    key={`${feature.name}-${tool.name}`}
                    className={`border-2 border-[#1B1B41] px-8 py-3 text-center ${rowIndex === 0 && colIndex === 0
                        ? "rounded-tl-[24px]"
                        : ""
                      } ${rowIndex === 0 && colIndex === tools.length - 1
                        ? "rounded-tr-[24px]"
                        : ""
                      } ${rowIndex === features.length - 1 && colIndex === 0
                        ? "rounded-bl-[24px]"
                        : ""
                      } ${rowIndex === features.length - 1 &&
                        colIndex === tools.length - 1
                        ? "rounded-br-[24px]"
                        : ""
                      }`}
                  >
                    {tool.features.includes(feature.name) ? (
                      <span className="text-white">✓</span>
                    ) : (
                      <span className="text-gray-300">✗</span>
                    )}

                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FeatureComparisonTable;
