import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import Logo from "../../Assets/Svg/Logo.svg";
import "../../App.css";
import { HOME, PRICING } from "../../Utils/Constants/RouteConstant";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const isPricingActive = location.pathname === PRICING;
  return (
    <header className="w-[95%] z-50 max-w-[1200px] mx-auto">
      <div className="flex justify-between items-center py-2 bg-blue bg-opacity-15 backdrop-blur-lg">
        <Link to={HOME}>
          <div className="flex items-center space-x-4">
            <img
              src={Logo}
              alt="DrCode.ai"
              className="h-32 w-32"
              style={{ verticalAlign: "middle" }}
            />
          </div>
        </Link>
        <div className="sm:hidden">
          <button
            onClick={toggleMobileMenu}
            className="text-white focus:outline-none"
            aria-label={isMobileMenuOpen ? "Close menu" : "Open menu"}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              )}
            </svg>
          </button>
        </div>
        {!isMobileMenuOpen && (
          <div className="hidden sm:flex items-center space-x-4">
            <Link to={PRICING}>
              <p
                className={`text-sm text-gray-400 mb-4 sm:mb-0 ${isPricingActive
                    ? "underline underline-offset-4 decoration-[#875BF8]"
                    : ""
                  }`}
              >
                Pricing
              </p>
            </Link>
            <div className="border-l-[1px] border-[#2E2E60] h-8"></div>
            <button
              onClick={() =>
                window.open("https://discord.gg/b7Cc3RyZXV", "_blank")
              }
              className="border-2 border-[#875BF8] rounded-lg text-[#875BF8] font-base py-2 px-3 transition flex items-center space-x-2 hover:bg-[#875BF8] hover:text-white"
            >
              <FaDiscord className="text-lg" />
              <span className="text-base">Join Discord</span>
            </button>
            <button
              onClick={() => handleNavigate("/login")}
              className="bg-[#875BF8] border border-[#875BF8] rounded-lg text-white font-base py-2 px-8 transition hover:bg-[#7649d9]"
            >
              <span className="text-base">Login</span>
            </button>
          </div>
        )}
      </div>
      {isMobileMenuOpen && (
        <div className="sm:hidden flex flex-col items-center bg-[#875BF8] bg-opacity-15 backdrop-blur-lg p-4 space-y-4 rounded-lg">
          <button
            onClick={() =>
              window.open("https://discord.gg/b7Cc3RyZXV", "_blank")
            }
            className="border-2 border-[#875BF8] rounded-lg text-[#875BF8] font-base py-2 px-3 transition flex items-center space-x-2 hover:bg-[#875BF8] hover:text-white justify-center"
          >
            <FaDiscord className="text-lg" />
            <span className="text-base">Join Discord</span>
          </button>

          <button
            onClick={() => handleNavigate("/login")}
            className="bg-[#875BF8] border border-[#875BF8] rounded-lg text-white font-base py-2 px-14 transition hover:bg-[#7649d9] "
          >
            <span className="text-base">Login</span>
          </button>
        </div>
      )}
    </header>
  );
};

export default Header;
