import React, { useRef, useEffect } from "react";
import submitButton from "../../../../Assets/Svg/submitButton.svg";
interface AutoResizeTextareaProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  isInitialLoading: boolean;
  handleSubmit: (e: React.FormEvent) => Promise<void>;
}

const AutoResizeTextarea: React.FC<AutoResizeTextareaProps> = ({
  value,
  onChange,
  onKeyDown,
  placeholder,
  isInitialLoading,
  handleSubmit,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const minHeight = 46; // Smaller default height
  const maxHeight = 120;

  useEffect(() => {
    if (textareaRef.current) {
      if (!value.trim()) {
        // Reset to minimum height when the content is cleared
        textareaRef.current.style.height = `${minHeight}px`;
      } else {
        // Adjust the height only when necessary
        adjustHeight();
      }
    }
  }, [value]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${minHeight}px`; // Reset height first to prevent growing too tall
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = `${Math.min(
        scrollHeight,
        maxHeight
      )}px`;
    }
  };

  const handlePaste = () => {
    // Call adjustHeight when content is pasted
    adjustHeight();
  };

  return (
    <div className="flex w-full items-end bg-[#131330] border-2 border-[#2E2E60] gap-1 rounded-lg">
      <div className="relative flex-grow">
        <textarea
          ref={textareaRef}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onPaste={handlePaste}
          placeholder={placeholder}
          // disabled={isInitialLoading}
          className="w-full input-field p-2 text-[14px] resize-none overflow-y-auto min-scrollbar"
          style={{
            minHeight: `${minHeight}px`,
            maxHeight: `${maxHeight}px`,
            lineHeight: "1.5",
            paddingTop: "15px",
            paddingBottom: "10px",
          }}
        />
      </div>
      <div className="relative ml-[10px] mb-1 z-10">
        <button
          type="submit"
          className={`w-10 h-10 flex items-center justify-center bg-[#06060D] rounded-lg transition-colors mr-1  mb-0.5 ${
            isInitialLoading ? "opacity-50 cursor-not-allowed" : ""
          }`}
          disabled={isInitialLoading}
          onClick={(e) => {
            e.preventDefault();
            handleSubmit(e);
          }}
        >
          <img src={submitButton} alt="Submit" className="w-10 h-10" />
        </button>
      </div>
    </div>
  );
};

export default AutoResizeTextarea;
