import React from "react";
import PriceCard from "./PriceCard";
import chatIcon from "../../Assets/Svg/ChatCode.svg";
import codePulseIcon from "../../Assets/Svg/codetest.svg";
import codeTestIcon from "../../Assets/Svg/TestTube.svg";
interface Feature {
  title?: string;
  icon?: string;
  points: string[];
  subtitle?: string;
  more?: string;
}

const organizationFeatures: Feature[] = [
  {
    subtitle:
      "Includes all features from Pro, plus: ",

    points: [
      "Collaborative features",
      "Personalized code reviews",
      "Self-learning models",
      "Automated security testing ",
      "Automated performance testing ",
      "Customizable reports & real time metrics"
    ],
    more: "+ Many more",
  },
];

const freeTrialFeatures: Feature[] = [
  {
    title: "Code Review",
    icon: chatIcon,
    points: [
      "Unlimited pull request reviews",
      "Line-by-line review of the code",
      "Chat with code assist bot",
      "Customizable reports",
    ],
  },
  {
    title: "Code Pulse",
    icon: codePulseIcon,
    points: ["Error tracking", "Unlimited repositories", "Single click root cause analysis"],
  },
  {
    title: "Code Test",
    icon: codeTestIcon,
    points: ["Automated front end and API testing", "Automated unit tests generation", "Unlimited APIs "],
  },
];
const PriceBox = () => {
  return (
    <div className="flex flex-col md:flex-row  justify-center gap-5 px-5 lg:px-0">
      <PriceCard
        title="Pro"
        tags={["Single user", "Small & medium sized teams"]}
        price="INR 800"
        pricePerMonth="per user/month"
        extraFeature="(Free for open source projects)"
        features={freeTrialFeatures}
        buttonText="Start free 15-Day trial"
        cardText="No credit card required for trial"
      />
      <PriceCard
        title="Organization"
        tags={["Large organizations"]}
        price="Custom pricing"
        pricePerMonth="Billed monthly"
        features={organizationFeatures}
        buttonText="Get in touch"
      />
    </div>
  );
};

export default PriceBox;
