import React from "react";
import cursor from "../../Assets/Svg/cursor.svg";
import leftArraow from "../../Assets/Svg/LeftCornerArrow.svg";
import { FaGithub, FaBitbucket } from "react-icons/fa";
import Logo from "../../Assets/Svg/Logo.svg";
import { setDataOnLocalStorage } from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";

const Login = () => {
  const handleLogin = async (carrier: string) => {
    const apiURL = `${process.env.REACT_APP_API_URL}bucket=auth&operationName=login&carrier=${carrier}`;
    setDataOnLocalStorage(LOCAL_STORAGE_DATA_KEYS.CARRIER, carrier);

    try {
      // Make a request to the loginWithGithub endpoint
      const response = await fetch(apiURL, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Get the redirect URL from the response
      const responseData = await response.json();

      // Get the redirect URL from the response
      const githubAuthUrl = responseData.data.url;

      // Redirect the browser to GitHub for authentication
      window.location.href = githubAuthUrl;
    } catch (error) {
      console.error("Error initiating GitHub login:", error);
    }
  };

  return (
    <div className="relative min-h-screen bg-[#080814]">
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-[#131330] shadow-md rounded-3xl mt-4 backdrop-blur-lg py-8 px-14 h-full">
          <div className="text-center space-y-4">
            <div className="flex items-center ml-5 sm:mb-0">
              <img src={Logo} alt="DrCode.ai" className="h-32 w-32 mr-2" />
              {/*<h1 className="text-3xl font-semibold text-white font-spaceGrotesk">DrCode AI</h1>*/}
            </div>
            <p className="text-white">Get started in minutes</p>
            <div className="space-y-4">
              <button
                onClick={() => handleLogin("github")}
                className="flex items-center justify-center w-full text-white px-6 py-2 rounded-xl shadow-lg border border-[#2D2D45] border-solid"
              >
                <FaGithub className="mr-3" />
                Continue with GitHub
              </button>
              <button
                onClick={() => handleLogin("bitbucket")}
                className="flex items-center justify-center w-full text-white px-6 py-2 rounded-xl shadow-lg border border-[#2D2D45] border-solid"
              >
                <FaBitbucket className="mr-3" />
                Continue with BitBucket
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Login;
