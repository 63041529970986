export interface Point {
  title: string;
  subtext?: string;
  para?: string;
  para2?: string;
  para3?: string;
  para4?: string;
  point1?: string;
  point2?: string;
  point3?: string;
}

// Define the points array with the type Point[]
export const points: Point[] = [
  {
    title: "1. Introduction",
    para: "The purpose of this Privacy Policy is to inform users of Dr Code about how we collect, use, disclose, and safeguard their personal information when they interact with our website. It also aims to ensure transparency regarding the types of data we collect and the measures we take to protect it. By using our website, users agree to the terms outlined in this Privacy Policy, which is intended to build trust and help them understand their rights concerning their data. At Dr. Code, we are committed to respecting and protecting the privacy of our users. We recognize the importance of maintaining the confidentiality and security of personal data and strictly adhere to applicable data protection laws, including the Information Technology Act, 2000, and the Rules enacted thereunder. We employ best practices and industry standards to safeguard user information and are dedicated to ensuring that users have control over their data. Our commitment to data privacy is integral to our operations, and we continuously strive to provide a secure online experience for our users.",
  },
  {
    title: "2. Data Collection",
    subtext:
      "We collect information during the code review process, including but not limited to:",
    point1: "Code uploaded for review ",
    point2: "Communication during reviews",
  },
  {
    title: "3. Use of Data",
    point1:
      "Ephemeral Data: Data collected during code reviews is used solely for that purpose. Queries to our Large Language Models (LLMs) are ephemeral with zero retention.",
    point2:
      "Enhancements: We may store embeddings generated from chat conversations to enhance future reviews for your organization. This data is anonymized and aggregated.",
    point3:
      "Integration: We may integrate embeddings from workflow systems (such as Linear, Jira, GitHub/GitLab) to assess the impact of code changes on existing issues.",
  },
  {
    title: "4. Data Storage",
    point1:
      "Data downloaded during the review process is temporarily stored in memory and deleted once the review is completed.",
    point2: "We do not store code or any personal data for any other purpose.",
  },
  {
    title: "5. Data Sharing",
    point1:
      "We do not share any data collected during the code review process with third parties, including our LLM providers.",
  },
  {
    title: "6. Compliance and Data Privacy",
    subtext: "Users agree to:",
    point1:
      "We comply with SOC2 Type II and GDPR standards to ensure that your data is kept confidential and secure.",
    point2:
      "Your data is isolated by your organization and used only to improve your organization’s reviews.",
  },
  {
    title: "7. Opting Out",
    point1:
      "You may opt out of data storage at any time. Opting out will not affect your access but may limit the personalization of your reviews.",
  },
  {
    title: "8. Changes to This Privacy Policy",
    para: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. We encourage you to review this Privacy Policy periodically for any changes.",
  },
  {
    title: "9. Contact Us",
    para: "If you have any questions or concerns about this Privacy Policy, please contact us at hello@drcode.ai",
  },
];
