import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FiChevronLeft, FiChevronRight, FiMenu, FiX } from "react-icons/fi";
import SettingIcon from "../../Assets/Svg/SettingsIcon.svg";
import ChatSquare from "../../Assets/Svg/Chat_Square.svg";
import Statistics from "../../Assets/Svg/Statistics.svg";
import ChatIcon from "../../Assets/Svg/ChatIcon.svg";
import Logout from "../../Assets/Svg/Logout.svg";
import Home from "../../Assets/Svg/Home.svg";
import Logo from "../../Assets/Svg/Logo.svg";
import minLogo from "../../Assets/Svg/minLogo.svg";
import { FaChevronRight, FaChevronUp, FaChevronDown } from "react-icons/fa";

import {
  CHAT_SUPPORT,
  CODE_REVIEW,
  SETTINGS,
  CODE_PULSE,
  DASHBOARD,
  CODE_TEST,
} from "../../Utils/Constants/RouteConstant";
import Profile from "./Profile";
import { setDataOnLocalStorage } from "../../Utils/globalUtilities";
import { LOCAL_STORAGE_DATA_KEYS } from "../../Utils/Constants/localStorageDataModels";

interface OrgData {
  id: string;
  org_name: string;
  avatar_url: string;
}

interface SidebarProps {
  className?: string;
  userData?: {
    orgs: OrgData[];
    selectedOrg?: OrgData;
  };
  onLogout?: () => void;
  isFullSidebar: boolean;
  setIsFullSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const SideBar: React.FC<SidebarProps> = ({
  className,
  onLogout,
  setIsFullSidebar,
  isFullSidebar,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>("home");

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath === DASHBOARD) {
      setSelectedTab("home");
      setIsFullSidebar(true);
    } else if (currentPath === CODE_REVIEW) {
      setSelectedTab("codeReview");
      setIsFullSidebar(true);
    } else if (currentPath === CODE_PULSE) {
      // No slug case, just set the tab
      setSelectedTab("codePulse");
    } else if (currentPath.startsWith("/pulse/")) {
      // Slug present, set the tab and collapse the sidebar
      setSelectedTab("codePulse");
      setIsFullSidebar(false);
    } else if (currentPath === CHAT_SUPPORT) {
      setSelectedTab("chatSupport");
      setIsFullSidebar(false);
    } else if (currentPath === SETTINGS) {
      setSelectedTab("settings");
      setIsFullSidebar(true);
    }
  }, [location]);

  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }
  };

  const handleItemClick = (tab: string, route: string) => {
    setSelectedTab(tab);
    navigate(route);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleSidebarMode = () => {
    setIsFullSidebar(!isFullSidebar);
  };
  const Tooltip: React.FC<{ content: string }> = ({ content }) => (
    <div className="absolute left-full top-1/2 transform -translate-y-1/2 ml-2 px-2 py-1 bg-[#1B1B41] text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 whitespace-nowrap z-50">
      {content}
    </div>
  );
  return (
    <div className={`relative h-screen flex ${className} z-10`}>
      <button
        onClick={toggleSidebar}
        className="absolute top-4 left-4 z-50 md:hidden"
      >
        {isSidebarOpen ? (
          <FiX size={24} color="white" />
        ) : (
          <FiMenu size={24} color="white" />
        )}
      </button>

      <div
        className={`fixed top-0 left-0 ${
          isFullSidebar ? "w-64" : "w-20"
        } h-full bg-[#0D0D22] text-white flex flex-col justify-between transition-all duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0 border-r border-[#1B1B41]`}
      >
        <button
          onClick={toggleSidebarMode}
          className="absolute top-4 -right-3 bg-[#0D0D22] text-white p-1 rounded-full z-50 border border-[#1B1B41]"
        >
          {isFullSidebar ? (
            <FiChevronLeft size={20} />
          ) : (
            <FiChevronRight size={20} />
          )}
        </button>

        <div className="flex flex-col px-4">
          <div
            className={`flex items-center mb-5 ${
              isFullSidebar ? "space-x-1" : "justify-center"
            } mt-3`}
          >
            <div
              className={`${
                isFullSidebar ? "h-32 w-32" : "h-8 w-8"
              } rounded-full flex justify-center items-center cursor-pointer`}
              onClick={() => navigate(DASHBOARD)}
            >
              {isFullSidebar ? (
                <img src={Logo} alt="logo" className="w-full h-full px-2" />
              ) : (
                <img src={minLogo} alt="logo" className="w-full h-full" />
              )}
            </div>
          </div>

          <nav className="flex flex-col space-y-2">
            {[
              { icon: Home, label: "Home", tab: "home", route: DASHBOARD },
              {
                icon: ChatSquare,
                label: "Code Review",
                tab: "codeReview",
                route: CODE_REVIEW,
              },
              {
                icon: Statistics,
                label: "Code Pulse",
                tab: "codePulse",
                route: CODE_PULSE,
              },
              {
                icon: ChatIcon,
                label: "Code Assist",
                tab: "chatSupport",
                route: CHAT_SUPPORT,
              },
            ].map((item) => (
              <div
                key={item.tab}
                onClick={() => handleItemClick(item.tab, item.route)}
                className={`relative flex items-center group ${
                  isFullSidebar ? "space-x-3" : "justify-center"
                } py-2 px-1 cursor-pointer rounded-lg ${
                  selectedTab === item.tab
                    ? "bg-[#24243D]"
                    : "hover:bg-[#19192D]"
                }`}
              >
                <img src={item.icon} alt={item.label} className="w-6 h-6" />
                {isFullSidebar && (
                  <span
                    className={
                      selectedTab === item.tab ? "text-white" : "text-[#62627C]"
                    }
                  >
                    {item.label}
                  </span>
                )}
                {!isFullSidebar && <Tooltip content={item.label} />}
              </div>
            ))}
          </nav>
        </div>
        <Profile
          isFullSidebar={isFullSidebar}
          selectedTab={selectedTab}
          onLogout={handleLogout}
          onSettingsClick={() => handleItemClick("settings", SETTINGS)}
        />
      </div>
    </div>
  );
};

export default SideBar;
