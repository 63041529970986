import React from "react";
import Header from "../Common/Headers";

import PriceBox from "./PriceBox";
import HeroTxt from "../Common/HeroTxt";
import PriceTable from "./PriceTable";
import FeatureComparisonTable from "./FeatureComparisonTable";
import FAQ from "./FAQ";
import Banner from "./Banner";
import Footer from "../Common/Footer";

const Pricing = () => {
  return (
    <div className="w-full h-full pb-10 ">
      <div
        className="w-full  "
        style={{
          background:
            "linear-gradient(180deg, rgba(77, 104, 255, 0.15) 0%, rgba(10, 10, 31, 0) 100%)",
        }}
      >
        <Header />
        <HeroTxt
          title="Our pricing"
          para="Simple and flexible per-user pricing"
          subtext="Free for open source projects"
        />
      </div>
      <PriceBox />
      <PriceTable />
      <FeatureComparisonTable />
      <FAQ />
      <div className="my-32">
        <Banner />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;
