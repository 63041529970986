export interface Point {
  title: string;
  subtext?: string;
  para?: string;
  point1?: string;
  point2?: string;
  point3?: string;
  point4?: string;
}

// Define the points array with the type Point[]
export const points: Point[] = [
  {
    title: "1. Acceptance of Terms",
    para: "By using DrCode's services, you agree to be bound by these Terms and Conditions. If you do not agree, please do not use our services.",
  },
  {
    title: "2. Services Provided",
    para: "DrCode provides automated code review, bug finder and fixing tool to assist users in analyzing and improving their code.",
  },
  {
    title: "3. Free Trial",
    para: "We offer a 15-day free trial of our services. After the trial period, you may be required to select a subscription plan to continue using our services.",
  },
  {
    title: "4. Refund and Cancellation Policy",
    point1: "All payments are non-refundable.",
    point2: "Users may take advantage of a free trial without needing to enter credit card information.",
    point3: "To start a paid subscription, payment method needs to be added.",
    point4: "If you decide to cancel your subscription, you can discontinue at any time and no further charges will be deducted."
  },
  {
    title: "5. User Obligations",
    subtext: "Users agree to:",
    point1: "Provide accurate information during the registration process.",
    point2:
      "Use our services in compliance with all applicable laws and regulations.",
  },
  {
    title: "6. Data Usage and Privacy",
    para: "Your use of DrCode is governed by our Privacy Policy, which outlines how we collect, use, and protect your data.",
  },
  {
    title: "7. Limitation of Liability",
    para: "In no event shall DrCode or Airia Technologies be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of our services.",
  },
  {
    title: "8. Changes to Terms",
    para: "Your use of DrCode is governed by our Privacy Policy, which outlines how we collect, use, and protect your data.",
  },
  {
    title: "9. Governing Law",
    para: "These Terms and Conditions shall be governed by and construed in accordance with the laws of India.",
  },
  {
    title: "10. Contact Us",
    para: "If you have any questions about these Terms and Conditions, please contact us at hello@drcode.ai",
  },
];
