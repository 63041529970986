import React, { useEffect, useState } from "react";
// import { getSessionID, useUser } from "../../UserContext";
import axios from "axios";
import celebrate from "../../Assets/celebrate.png";
import Github from "../../Assets/Svg/github_1.svg";
import Bitbucket from "../../Assets/Svg/bitbucket_1.svg";
import PenSquare from "../../Assets/Svg/Pen_Square.svg";
import Magnifier_Bug from "../../Assets/Svg/Magnifer_Bug.svg";
import card_1 from "../../Assets/Svg/aiChatSupportCard.svg";
import card_2 from "../../Assets/Svg/codetest2Card.svg";
import manageRepo from "../../Assets/Svg/manage.svg";
import chatSquare from "../../Assets/Svg/chatsquare.svg";
import settingManage from "../../Assets/Svg/settingManageButton.svg";
import ActionCard from "../Common/ActionCard";
import SquareArrowRight from "../../Assets/Svg/Square_Arrow_Right.svg";
import { getDataFromLocalStorage } from "../../Utils/globalUtilities";
import {
  LOCAL_STORAGE_DATA_KEYS,
  UserData,
} from "../../Utils/Constants/localStorageDataModels";
import AnimationLoader from "../Common/AnimationLoader";

interface HomeTabProps {
  className?: string;
}

const HomeTab: React.FC<HomeTabProps> = ({ className }) => {
  const token = localStorage.getItem("sessionId");
  const [listAllRepo, setListAllRepo] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectedOrg: string | null =
    getDataFromLocalStorage(LOCAL_STORAGE_DATA_KEYS.SELECTED_ORG) ||
    "No organization";

  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const storedUserData = getDataFromLocalStorage<UserData>(
      LOCAL_STORAGE_DATA_KEYS.USER_DATA
    );
    if (storedUserData && typeof storedUserData === "object") {
      setUserData(storedUserData as UserData);
    }
  }, []);

  const handleConnect = async () => {
    const apiURL = `${process.env.REACT_APP_API_URL}bucket=account&operationName=gitAppInstallationUrl&carrier=${userData?.carrier}`;

    try {
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: token,
        },
      });
      const githubAccessUrl = response.data.data.url;
      window.location.href = githubAccessUrl;
    } catch (error) {
      console.error("Error initiating GitHub login:", error);
      setLoading(false);
    }
  };

  const listRepositories = async () => {
    const apiURL = `${
      process.env.REACT_APP_API_URL
    }bucket=pulse&operationName=listRepositories&orgName=${encodeURIComponent(
      selectedOrg
    )}`;

    try {
      setLoading(true);
      const response = await axios.get(apiURL, {
        headers: {
          Authorization: token,
        },
      });
      const datalength = response?.data?.data?.length || 0;
      setListAllRepo(datalength);
    } catch (error) {
      console.error("Error listRepositories :", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedOrg !== "No organization") {
      listRepositories();
    }
  }, [selectedOrg]);
  const contactUsEmail = (email: string) => {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${email}`;
    window.open(mailtoLink, "_blank");
  };
  return loading == true ? (
    <AnimationLoader />
  ) : (
    <div
      className={`flex flex-col justify-center overflow-auto p-0 bg-[#080814] h-screen ${className} no-scrollbar`}
    >
      {listAllRepo == 0 ? (
        <div className="flex flex-col items-center h-screen py-6">
          <div className="px-6 py-4">
            <div className="flex justify-center items-center">
              <img src={celebrate} alt="celebrate" className="mb-3" />
            </div>
            <h1 className="text-[32px] font-[700] text-center text-[#D1D1E3]">
              Welcome, {userData?.name ? userData.name : userData?.username}
              <p className="text-[14px] font-normal text-[#8B8B99] mt-2 leading-tight">
                Thank you for joining DrCode!
                <br /> We're excited to enhance your coding experience with
                AI-assisted code reviews.
              </p>
            </h1>
          </div>
          <div className="w-[64%]">
            <h2 className="text-[16px] font-bold text-[#D1D1E3]">
              Here's how you can get started{" "}
            </h2>
            <div className="bg-[#0D0D22] shadow-md rounded-3xl mt-3 py-6 px-10 h-[90%]">
              <ActionCard
                imageSrc={userData?.carrier === "github" ? Github : Bitbucket} // Conditional image based on carrier
                title={
                  userData?.carrier === "github"
                    ? "Connect Your GitHub"
                    : "Connect Your BitBucket"
                }
                description={
                  userData?.carrier === "github"
                    ? "Link your GitHub account to DrCode for seamless integration and instant access to code reviews."
                    : "Link your BitBucket account to DrCode for seamless integration and instant access to code reviews."
                }
                onClick={handleConnect}
                iconImg={SquareArrowRight}
              />

              <hr className="w-full border-[#131330] my-4" />
              <ActionCard
                imageSrc={PenSquare}
                title="Create a Project"
                description="Start a new project or import an existing one to see DrCode in action."
              />
              <hr className="w-full border-[#131330] my-4" />
              <ActionCard
                imageSrc={Magnifier_Bug}
                title="Review and Improve"
                description="Let our AI analyze your code, offering suggestions and identifying potential issues to improve your code quality."
              />
            </div>
          </div>
          <div className="flex flex-col my-5 w-[64%] pb-10">
            <p className="text-[#666680] text-[13px] mb-5">
              If you need any assistance, our support team is here to help. Feel
              free to explore our Help Center or contact us at
              <span
                className="text-[#875BF8] cursor-pointer hover:underline underline ml-2"
                onClick={() => contactUsEmail("support@drcode.ai.")}
              >
                support@drcode.ai.
              </span>
            </p>
            <h2 className="text-[#D1D1E3] text-[16px] font-bold mb-5 text-center md:text-left">
              Upcoming Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <img src={card_1} alt="card" className="w-full h-auto" />
              <img src={card_2} alt="card" className="w-full h-auto" />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center h-screen py-6">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-[40px] text-2xl text-[#FFFFFF] my-4">✨</h1>
            <h1 className="text-[#B2B2C1] text-[32px] font-bold">
              You're All Set!
            </h1>
            <p className="text-[#8B8B99] text-[14px] font-normal">
              You can now start reviewing your code. Manage your repositories or
              begin reviewing.
            </p>
          </div>
          <div className="w-[64%]">
            <h2 className="text-[16px] font-bold text-[#D1D1E3]">
              Here's how you can get started{" "}
            </h2>
            <div className="bg-[#0D0D22] shadow-md rounded-3xl mt-3 py-6 px-10 h-[90%]">
              <ActionCard
                imageSrc={manageRepo}
                title="Manage Repositories"
                description="Add or remove repositories at any time to keep your code reviews focused on the right projects."
                iconImg={settingManage}
                onClick={handleConnect}
              />
              <hr className="w-full border-[#131330] my-4" />
              <ActionCard
                imageSrc={chatSquare}
                title="Start Reviewing"
                description="Dive into your code reviews and let DrCode help you catch issues early."
              />
            </div>
          </div>
          <div className="w-[64%] my-5 pb-10">
            <p className="text-[#666680] text-[14px] mb-5">
              If you need any assistance, our support team is here to help. Feel
              free to explore our Help Center or contact us at
              support@drcode.ai.
            </p>
            <div className="flex flex-col">
              <h2 className="text-[#D1D1E3] text-[16px] font-[700] mb-5 text-center md:text-left">
                Upcoming Features
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <img src={card_1} alt="card" className="w-full h-auto" />
                <img src={card_2} alt="card" className="w-full h-auto" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HomeTab;
