import React from "react";
import { RxDotFilled } from "react-icons/rx";

interface ContentProps {
  title: string;
  subtext?: string;
  para?: string;
  para2?: string,
  para3?: string,
  para4?: string,
  point1?: string;
  point2?: string;
  point3?: string;
  point4?: string;
}

const Content: React.FC<ContentProps> = ({
  title,
  subtext,
  para,
  para2,
  para3,
  para4,
  point1,
  point2,
  point3,
  point4
}) => {
  const contactUsEmail = (email: string) => {
    const mailtoLink = `https://mail.google.com/mail/?view=cm&to=${email}`;
    window.open(mailtoLink, "_blank");
  };
  const parts = para?.split(/(hello@drcode\.ai)/);
  return (
    <div className="mb-6">
      <h2 className="font-bold text-xl mb-2 text-[#B2B2C1]">{title}</h2>
      {subtext && (
        <h3 className="text-lg font-normal mb-2 text-[#B2B2C1]">{subtext}</h3>
      )}
      <p className="mb-2 text-[#B2B2C1] text-justify">
        {parts?.map((part, index) =>
          part === "hello@drcode.ai" ? (
            <span
              key={index}
              className="text-[#875BF8] cursor-pointer hover:underline"
              onClick={() => contactUsEmail("hello@drcode.ai")}
            >
              {part}
            </span>
          ) : (
            part
          )
        )}
      </p>
      <ul className="list-none pl-0">
        {point1 && (
          <li className="flex items-start mb-2 text-[#B2B2C1]">
            <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
            <span>{point1}</span>
          </li>
        )}
        {point2 && (
          <li className="flex items-start mb-2 text-[#B2B2C1]">
            <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
            <span>{point2}</span>
          </li>
        )}
        {point3 && (
          <li className="flex items-start mb-2 text-[#B2B2C1]">
            <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
            <span>{point3}</span>
          </li>
        )}
        {point4 && (
          <li className="flex items-start mb-2 text-[#B2B2C1]">
            <RxDotFilled className="mt-1.5 mr-2 flex-shrink-0" />
            <span>{point4}</span>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Content;
