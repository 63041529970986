import React, { useEffect, useRef, useState } from 'react';
import { toast } from "react-toastify";
import axios from "axios";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    emailInput?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const ContactUsModal: React.FC<ModalProps> = ({ isOpen, closeModal, emailInput }) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState(emailInput ? emailInput : "");
    const [phone, setPhone] = useState("");
    const [company, setCompany] = useState("");

    const [nameError, setNameError] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string | null>(null);
    const [phoneError, setPhoneError] = useState<string | null>(null);

    const nameInputRef = useRef<HTMLInputElement>(null); 

    useEffect(() => {
        if (isOpen && nameInputRef.current) {
            nameInputRef.current.focus(); 
        }
    }, [isOpen]);

    const validateFields = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^[0-9]{10}$/;
    
        let isValid = true;
    
        if (!name) {
            setNameError("Oops! We need your name to personalize things.");
            isValid = false;
        } else {
            setNameError(null);
        }
    
        if (!email || !emailRegex.test(email)) {
            setEmailError("We promise not to spam! But please provide a valid email.");
            isValid = false;
        } else {
            setEmailError(null);
        }
    
        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError("A quick call? Just need your valid 10-digit phone number.");
            isValid = false;
        } else {
            setPhoneError(null);
        }
    
        return isValid;
    };
    

    const sendEmailHandler = async () => {
        if (!validateFields()) return;

        try {
            const response = await axios.post(
                `${API_URL}bucket=auth&operationName=contactus`,
                { name, email, phone, company },
                { headers: { "Content-Type": "application/json" } }
            );

            const responseMessage = response.data.message;

            if (responseMessage === "ok") {
                toast.success("Thanks for reaching out! We'll get back to you soon.");
            } else {
                toast.error(responseMessage);
            }
            closeModal();
        } catch (error) {
            console.error("Error sending contact request:", error);
            toast.error("An error occurred while sending your request.");
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 p-8">
            <div className="fixed inset-0 bg-[#06060D] opacity-95"></div>
            <div className="bg-[#0D0D22] rounded-2xl shadow-lg p-6 w-full max-w-xl z-10 relative">
                <button
                    className="absolute top-3 right-3 text-[#D1D1E3] text-2xl focus:outline-none"
                    onClick={closeModal}
                >
                    &times;
                </button>
                <div className="mt-6 flex flex-col">
                    <h2 className="text-[12px] font-200 text-[#D1D1E3]">Name</h2>
                    <input
                        ref={nameInputRef}
                        type="text"
                        placeholder="John Doe"
                        className="mt-1 p-2 w-full rounded-[12px] focus:outline-none focus:border-[#2E2E60] focus:border bg-[#0A0A1F] text-[#D1D1E3] placeholder-[#62627C] border border-transparent"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                {nameError && <p className="text-red-500 text-[10px] mt-1 ml-2">{nameError}</p>}
                <div className="mt-4 flex flex-col">
                    <h2 className="text-[12px] font-200 text-[#D1D1E3] w-[30%]">Email</h2>
                    <input
                        type="email"
                        placeholder="johndoe@abc.com"
                        className="mt-1 p-2 w-full rounded-[12px] focus:outline-none focus:border-[#2E2E60] focus:border bg-[#0A0A1F] text-[#D1D1E3] placeholder-[#62627C] border border-transparent"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                {emailError && <p className="text-red-500 text-[10px] mt-1 ml-2">{emailError}</p>}
                <div className="mt-4 flex flex-col">
                    <h2 className="text-[12px] font-200 text-[#D1D1E3] w-[30%]">Phone</h2>
                    <input
                        type="tel"
                        placeholder="1234567890"
                        className="mt-1 p-2 w-full rounded-[12px] focus:outline-none focus:border-[#2E2E60] focus:border bg-[#0A0A1F] text-[#D1D1E3] placeholder-[#62627C] border border-transparent"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                {phoneError && <p className="text-red-500 text-[10px] mt-1 ml-2">{phoneError}</p>}
                <div className="mt-4 flex flex-col">
                    <h2 className="text-[12px] font-200 text-[#D1D1E3] w-[30%]">Company</h2>
                    <input
                        type="text"
                        placeholder="ABC company"
                        className="mt-1 p-2 w-full rounded-[12px] focus:outline-none focus:border-[#2E2E60] focus:border bg-[#0A0A1F] text-[#D1D1E3] placeholder-[#62627C] border border-transparent"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                </div>
                <div className="mt-6 flex justify-center">
                    <button
                        onClick={sendEmailHandler}
                        className="bg-[#0D0D22] rounded-xl border border-[#875BF8] text-[#875BF8] font-base py-2 px-6 mr-2"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ContactUsModal;
