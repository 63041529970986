import React from "react";
import { points } from "./points";
import Content from "./Content";
import Header from "../Common/Headers";

interface Point {
  title: string;
  subtext?: string;
  para?: string;
  point1?: string;
  point2?: string;
  point3?: string;
  point4?: string;
}

const Details: React.FC = () => {
  const splitIndex = Math.ceil(points.length * 0.4);
  const firstSection = points.slice(0, splitIndex);
  const secondSection = points.slice(splitIndex);

  return (
    <div className="w-full mb-10">
      <div
        className="w-full  "
        style={{
          background:
            "linear-gradient(180deg, rgba(77, 104, 255, 0.15) 0%, rgba(10, 10, 31, 0) 100%)",
        }}
      >
        <Header />
        <div className="text-center px-[14%] mt-5">
          <h1 className="text-4xl font-bold mb-2 text-[#D1D1E3]">
            Terms and Conditions
          </h1>
          <p className="mb-8 text-[16px] text-[#9494A1]">
            Effective Date: July 2024
          </p>
        </div>
        <div className=" px-[14%] my-10">
          {firstSection.map((data: Point, index: number) => (
            <Content
              key={index}
              title={data.title}
              subtext={data.subtext}
              para={data.para}
              point1={data.point1}
              point2={data.point2}
              point3={data.point3}
              point4={data.point4}
            />
          ))}
        </div>
      </div>
      <div className="w-full px-[14%]">
        {secondSection.map((data: Point, index: number) => (
          <Content
            key={index + splitIndex}
            title={data.title}
            subtext={data.subtext}
            para={data.para}
            point1={data.point1}
            point2={data.point2}
            point3={data.point3}
            point4={data.point4}
          />
        ))}
      </div>
    </div>
  );
};

export default Details;
