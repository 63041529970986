import React, { useEffect } from "react";
import Footer from "../../Components/Common/Footer";
import Details from "../../Components/TNC/Details";
import ScrollToTop from "../../Components/Common/ScrollToTop";

const TNCPage = () => {

  return (
    <div className="bg-[#080814] min-h-screen text-white  w-full max-w-[2050px] mx-auto">
      <ScrollToTop />
      <Details />
      <Footer />
    </div>
  );
};

export default TNCPage;
