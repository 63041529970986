import ChipList from "./ChipList";
import EmptyRepoImg from "../../../../../Assets/Svg/EmptyRepo.svg";
import { CODE_PULSE_CONFIGURE } from "../../../../../Utils/Constants/RouteConstant";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
const EmptyRepo = () => {
  const navigate = useNavigate();
  const handleStartButton = () => {
    navigate(CODE_PULSE_CONFIGURE);
  };

  return (
    <>
      <ChipList />
      <div className=" h-[85vh]  rounded-xl shadow-md  bg-[#080814]">
        <div className="flex flex-col justify-center items-center h-full">
          <img src={EmptyRepoImg} alt="emptyRepo" />
          <div className="mt-5 text-center">
            <h1 className="text-[#D1D1E3] text-[14px] font-bold">
              No Repositories Found
            </h1>
            <p className="text-[#B2B2C1] text-[14px] font-normal">
              It looks like you haven’t connected any repositories yet.
            </p>
          </div>
          <div className="my-3">
            <button
              className="bg-[#875BF8] text-[14px] text-white py-2 px-4 rounded-lg flex items-center space-x-2"
              onClick={handleStartButton}
            >
              <FaPlus className="text-white" />
              <span>Add Repositories</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmptyRepo;
