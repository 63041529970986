import React from 'react'
import Pricing from '../../Components/PricingPage/Pricing'

const PricingPage = () => {
  return (
    <div className="bg-[#080814] min-h-screen text-white  w-full max-w-[2050px] mx-auto">

      <Pricing/>
    </div>
  )
}

export default PricingPage