type LanguageCodeFunction = (params: { dsnURL: string }) => string;

export const NodeCmd = "npm i drcode-node";
export const PythonCmd = "pip install drcode-python";
export const GoCmd = "go get github.com/airia-in/drcode_go";
export const RustCmd = "cargo add drcode-rust";

export const customStyle = {
  hljs: {
    background: "#0D0D22", // Background color
  },

  "hljs-string": {
    color: "#0B7B69", // Color for strings
  },
  "hljs-comment": {
    color: "#D1D1E3", // Color for comments
  },
  "hljs-built_in": {
    color: "#8be9fd", // Color for built-in functions
  },
  "hljs-function": {
    color: "#ffff", // Color for function names
  },
  "hljs-variable": {
    color: "#F4C790", // Color for variables
  },
  "hljs-number": {
    color: "#F4C790", // Color for numbers (red)
  },
  // Add more custom styles here if needed
};

function parseDSN(dsnURL: string) {
  const url = new URL(dsnURL);

  const [publicKey, hostWithPort] = url.username
    ? [url.username, url.host]
    : [dsnURL.split("@")[0].split("://")[1], dsnURL.split("@")[1]];

  const [host, port] = hostWithPort.includes(":")
    ? hostWithPort.split(":")
    : [hostWithPort, ""];

  const projectId = url.pathname.split("/").pop();

  return {
    protocol: url.protocol.replace(":", ""), // Removing the colon at the end
    publicKey,
    host,
    port: port || "443", // Default to 443 if port is not specified
    projectId,
  };
}

export const languageCodeMap: { [key: string]: LanguageCodeFunction } = {
  Node: ({ dsnURL }) => {
    const { publicKey, projectId } = parseDSN(dsnURL);
    return `
const DrCode = require("drcode-node");

// Configuration for drcode
const config = {
  publicKey: '${publicKey}',
  projectId: ${projectId},
  tracesSampleRate: 1.0, // optional
  profilesSampleRate: 1.0 // optional
};

// Create an instance of DrCode
const drcode = new DrCode(config);
drcode.init();

// Middleware to handle errors in Express applications
app.use(DrCode.errorHandler);
    
`;
  },

  "Python 3": ({ dsnURL }) => {
    const { publicKey, projectId } = parseDSN(dsnURL);
    return `
from drcode_python import init_drcode
config = {
  'public_key': '${publicKey}',
  'project_id': ${projectId},
  'traces_sample_rate': 1.0,  # optional
  'profiles_sample_rate': 1.0  # optional

}

init_drcode(config)
    `;
  },

  Go: ({ dsnURL }) => {
    const { publicKey, projectId } = parseDSN(dsnURL);
    return `
package main

import (
    "fmt"
    "log"
    "net/http"
    drcode "github.com/airia-in/drcode_go"
)

func main() {
    err := drcode.Initialize("${projectId}", "${publicKey}")
    if err != nil {
        log.Fatalf("Failed to initialize DrCode: %v", err)
    }

    http.HandleFunc("/panic", drcode.ErrorHandler(func(w http.ResponseWriter, r *http.Request) {
        panic("test panic! drcode")
    }))

    http.HandleFunc("/error", func(w http.ResponseWriter, r *http.Request) {
        err := fmt.Errorf("test error drcode")
        drcode.ReportError(err)
        http.Error(w, "An error occurred and was reported to DrCode", http.StatusInternalServerError)
    })

    fmt.Println("Server is running on http://localhost:8080")
    log.Fatal(http.ListenAndServe(":8080", nil))
}
`;
  },
  Rust: ({ dsnURL }) => {
    const { publicKey, projectId } = parseDSN(dsnURL);
    return `
use drcode_rust::{Config, init, run_with_error_reporting};
use std::error::Error;

#[tokio::main]
async fn main() -> Result<(), Box<dyn Error>> {
    // Initialize the error reporting client with your public key and project ID
    let config = Config {
        public_key: "${publicKey}".to_string(),
        project_id: "${projectId}".to_string(),
    };

    let _guard = init(config);

    // This will be automatically reported
    let result = run_with_error_reporting(async {
        Err::<(), _>(std::io::Error::new(std::io::ErrorKind::Other,
         "Automatic error reporting test"))
    }).await;

    if let Err(e) = result {
        println!("An error occurred and was automatically reported: {:?}", e);
    }

    // This panic will be automatically caught and reported
    panic!("This is a test panic");

    Ok(())
}

`;
  },
};
